import { EditOutlined } from "@mui/icons-material";
import { Button, Icon, Typography } from "@mui/material";
import React from "react";

export const MainIconButton = ({onClick, disabled, title, icon}: any) => {
  return (
    <Button
      size={"small"}
      onClick={onClick}
      disabled={disabled}
      sx={{
        height: "100%",
      }}
    >
      <Icon className={icon === 'edit' ? "material-icons-outlined" : ''} fontSize={'small'} style={{fontSize: 14}}>
        {icon}
      </Icon>
      <Typography fontSize={13} marginLeft={1} fontWeight={400}>
        {title}
      </Typography>
    </Button>
  );
};
