import { Box } from "@mui/system";
import React, {forwardRef, ReactNode} from "react";

export type BaseInerPageProps = {
  title?: string;
  children: ReactNode,
}

export const BaseInnerPage:React.FC<BaseInerPageProps> = forwardRef(({children}, ref) => (
  <>
    <Box ref={ref}>
      {children}
    </Box>
  </>
));
