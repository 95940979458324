import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { EUserMenuActions } from "../models/EUserMenuActions";
import { ERoles } from "../models/ERoles";
import {
  AddCircleOutlineOutlined,
  MoreVert,
  RemoveOutlined,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

type UserOptionsMenuProps = {
  onActionClicked: (actionType: EUserMenuActions | null) => void;
  userRole: ERoles;
};

export const UserOptionsMenu: React.FC<UserOptionsMenuProps> = ({
  onActionClicked,
  userRole,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: any, actionType: EUserMenuActions | null) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
    onActionClicked(actionType);
  };

  return (
    <>
      <IconButton
        id="customized-button"
        aria-controls={open ? "customized-menu" : undefined}
        aria-haspopup="true"
        color={"primary"}
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disabled={userRole === ERoles.ADMIN}
      >
        <MoreVert />
      </IconButton>

      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          "aria-labelledby": "customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => handleClose(e, null)}
      >
        {/* <Divider sx={{ my: 0.5 }} /> */}
        {userRole === ERoles.COACH && (
          <MenuItem
            onClick={(e) => handleClose(e, EUserMenuActions.ASSIGN_CLIENT)}
            disableRipple
          >
            <AddCircleOutlineOutlined />
            Assign Client
          </MenuItem>
        )}
        {userRole === ERoles.USER && (
          <MenuItem
            onClick={(e) => handleClose(e, EUserMenuActions.ASSIGN_COACH)}
            disableRipple
          >
            <AddCircleOutlineOutlined />
            Assign Coach
          </MenuItem>
        )}
        <MenuItem
          onClick={(e) => handleClose(e, EUserMenuActions.EDIT)}
          disableRipple
        >
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem
          onClick={(e) => handleClose(e, EUserMenuActions.DELETE)}
          disableRipple
        >
          <RemoveOutlined />
          Delete
        </MenuItem>
      </StyledMenu>
    </>
  );
};
