import { createTheme } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';
import shadows, { Shadows } from '@mui/material/styles/shadows';

// A custom theme for this app
const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'dense',
        size: 'small'
      },
    }
  },
  palette: {
    background: {
      default: '#f5f7fc'
    },
    primary: {
      main: '#1A2177',
    },
    secondary: {
      main: '#7776f6',
    },
    error: {
      main: red.A400,
    },
    success: {
      main: green[400]
    },
    text: {
      primary: '#121858',
      secondary: '#7776f6',
      disabled: 'rgba(0,0,0,.5)',
    }
  },
  shadows: shadows.map((el, i) => {
    if (i === 0) {
      return 'none';
    }
    return '0px 6px 8px -2px rgb(0 0 0 / 7%), 0 0 0 1px rgb(18 24 88 / 0%)'
  }) as Shadows,
});

export default theme;