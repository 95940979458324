import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  CartesianGrid,
  LineChart,
} from "recharts";

import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { CustomChartTooltip } from "./CustomChartTooltip";
import { AxisDomain } from "recharts/types/util/types";
import { IWorkout } from "../models/IWrokoutData";
import { IActivityData } from "../models/IActivityData";
import { ISleepData } from "../models/ISleepData";
import { ICgmData } from "../models/ICgmData";
import { CustomMealDot } from "./CustomMealDot";
dayjs.extend(duration);
dayjs.extend(utc);

export type SummaryChartProps = {
  onPointClicked?: (props: any, ev?: any) => void;
  data: IWorkout[] | IActivityData[] | ISleepData[] | ICgmData[];
  metrics?: any[];
  dateKey: string;
  useDomains?: boolean;
  showMeals?: boolean;
  chartType: "workout" | "activity" | "sleep" | "cgm";
};

const colors = [
  "#F44336",
  "#1A237E",
  "#9C27B0",
  "#E91E63",
  "#2196F3",
  "#00BCD4",
  "#4CAF50",
  "#CDDC39",
  "#3F51B5",
  "#FF9800",
  "#FF5722",
  "#FFEB3B",
];

export const SummaryChart: React.FC<SummaryChartProps> = ({
  data,
  metrics,
  dateKey,
  useDomains,
  chartType,
  onPointClicked,
}) => {
  const getDomainsData = (): AxisDomain => {
    if (!useDomains || !data || !data.length) {
      return ["auto", "auto"];
    }
    const el = data[0] as any;
    return [
      dayjs(el[`${dateKey}`]).startOf("day").format(),
      //@ts-ignore
      dayjs(data[data.length - 1][`${dateKey}`])
        .endOf("day")
        .format(),
    ];
  };

  console.log("data", data)  //DEBUG
  console.log("metrics", metrics)  //DEBUG
  console.log("dateKey", dateKey)  //DEBUG

  return (
    <>
      {!data || (data.length === 0 && <>No chart without data</>)}
      <>
        {data && data.length > 0 && (
          <ResponsiveContainer width={"100%"} height={480}>
            <LineChart
              height={480}
              data={data}
              margin={{ top: 5, right: 10, left: 0, bottom: 5 }}
              //@ts-ignore
              onClick={(props: any) => onPointClicked && onPointClicked(props)}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={dateKey}
                tick={{ fontSize: 12 }}
                domain={getDomainsData()}
                tickFormatter={(val: string) => dayjs.utc(val).format("MMM DD")}
              />
              {chartType === 'cgm' && <YAxis hide domain={[0, 10]}/> }
              <Tooltip content={<CustomChartTooltip chartType={chartType} />} />
              <Legend />
              {metrics &&
                metrics.map((el, i) => {
                  console.log("el, i", { el: el, i: i})  //DEBUG
                  if (el.prop != "calendar_date"
                    && el.prop != "time_zone"
                    && el.prop != "timezone_offset"
                    && el.prop != "low"
                    && el.prop != "medium"
                    && el.prop != "high"
                  ) return (
                    !el.type &&
                    (el.prop === "record" ? (
                      <Line
                        name={'records'}
                        key={el.prop}
                        dataKey={el.prop}
                        dot={(props) => <CustomMealDot {...props} />}
                        activeDot={(props) => <CustomMealDot {...props} />}
                        connectNulls={false}
                        strokeWidth={0}
                        stroke={"coral"}
                      />
                    ) : (
                      <Line
                        key={el.prop}
                        type={"monotone"}
                        dataKey={el.prop}
                        stroke={colors[i]}
                      />
                    ))
                  );
                })}
            </LineChart>
          </ResponsiveContainer>
        )}
      </>
    </>
  );
};
