import React, { useEffect, useState } from "react";
import { Card, Typography, Box } from "@mui/material";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { buildMetricBlocks, mapTimelinerecordsForChart, getAverageForData } from "../utils/common.utils";
import { RangeDatePicker } from "./RangeDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  setCurrentRecordCategory,
  setPickerData,
  setTimelineRecords,
  setTimelineRecordsLoaded,
} from "../store/info.slice";
import { IRangeDate } from "../models/IRangeDate";
import { ICgmData, ICgmRawData } from "../models/ICgmData";
import { ChartsHolder, MetricsHolder } from "./CommonChart";
import { timelineService, vitalService } from "../services";
import { ITimelineRecord } from "../models/ITimelineRecord";
import { CGMChart } from "./CGMChart";
import { SummaryChart } from "./SummaryChart";
dayjs.extend(duration);
dayjs.extend(utc);

export type CgmTabDataProps = {
  cgmData: ICgmData[];
};

export const getCgmDataBlocks = (objectData: any) => {
  return buildMetricBlocks(objectData, [
    "id",
    "records",
    "timestamp",
    "type",
    "unit",
  ]);
};

export const CgmDataTab = ({ cgmData }: CgmTabDataProps) => {
  const client = useSelector((state: RootState) => state.client.client);
  const [dataProviders, setDataProviders] = useState<string[]>([]);
  const [blocks, setBlocks] = useState<any[]>([]);
  const [cgmSummary, setCgmSummary] = useState<ICgmData[]>(cgmData);
  const { cgmRangePicker } = useSelector((state: RootState) => state.info);
  const dispatch = useDispatch();

  const handleCgmRangePickerChanged = (dates: IRangeDate) => {
    dispatch(setPickerData({ pickerType: "cgm", dates }));
  };

  const loadCgmData = async (newDates: IRangeDate) => {
    if (!client?.vitalUserId) return;

    try {
      const glucoseData = await vitalService.getVitalsData(
        client?.vitalUserId as string,
        "glucose",
        newDates
      );

      const recordsData = await timelineService
        .getUserTimelineData(client.id, newDates)
        .then((resp) => {
          dispatch(setCurrentRecordCategory(null));
          dispatch(setTimelineRecords(resp));
          return resp;
        })
        .finally(() => dispatch(setTimelineRecordsLoaded(true)));

      // const mappedData = glucoseData.map((el: any) => ({
      //   ...el,
      //   timestamp: dayjs.utc(el.timestamp).valueOf(),
      // }));
      const mergedData = _getMergedData(glucoseData, recordsData)

      setCgmSummary(mergedData);
      const avgData = getAverageForData(mergedData);
      setBlocks(getCgmDataBlocks(avgData));
    } catch (error) {
      console.log(error, "Can not load cgm data");
    }
  };

  useEffect(() => {
    loadCgmData(cgmRangePicker);
  }, [cgmRangePicker]);

  const getGlucoseAvg = () => {
    return (
      (
        cgmSummary.reduce((acc, cv) => {
          acc += cv.value;
          return acc;
        }, 0) / cgmSummary.length
      ).toFixed(2) +
      " " +
      cgmSummary[0]?.unit
    );
  };

  const _getMergedData = (
    cgmData: ICgmData[],
    recordsData: ITimelineRecord[]
  ) => {
    const mappedRecords = mapTimelinerecordsForChart(recordsData, true);
    return cgmData.map((el) => {
      el.records = [];
      for (const r of mappedRecords) {
        if (
          r &&
          dayjs(el.timestamp).isSame(r.date, 'hour')
        ) {
          el.records.push(r);
          el.record = r.record;
        }
      }
      return el;
    });
  };

  return (
    <>
      <RangeDatePicker
        dates={cgmRangePicker}
        onSelected={handleCgmRangePickerChanged}
        title={"CGM data"}
      />
      {cgmData && (
        <>
          <MetricsHolder>
            <Card
              sx={{
                padding: 1,
                flex: "1 1 calc(20% - 16px)",
              }}
            >
              <Typography
                variant={"subtitle2"}
                textOverflow={"ellipsis"}
                noWrap
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                }}
              >
                Average {getGlucoseAvg()}
              </Typography>
            </Card>
          </MetricsHolder>

          <ChartsHolder>
            <Card className={"innerCard"}>
              <Typography
                variant={"subtitle1"}
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                }}
              >
                CGM summary chart
              </Typography>
              <Typography variant={"subtitle2"}>
                {dataProviders.length > 0 && (
                  <>
                    Based on providers:{" "}
                    {dataProviders.map((el: any, i) => (
                      <Box
                        component={"span"}
                        key={i}
                        sx={{
                          border: 1,
                          padding: "0 5px",
                          borderRadius: 2,
                          marginRight: 1,
                          fontSize: 12,
                        }}
                      >
                        {el}
                      </Box>
                    ))}
                  </>
                )}
              </Typography>
              {/* <CGMChart data={cgmSummary} /> */}
              <SummaryChart chartType={'cgm'} dateKey={'timestamp'} data={cgmSummary} metrics={blocks}/>
            </Card>
          </ChartsHolder>
        </>
      )}
    </>
  );
};
