import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { AppMainContent } from '../components/AppMainContent'
import { AppSideBar } from '../components/AppSideBar'
import { AppTopBar } from '../components/AppTopBar'
import { DrawerHeader } from '../components/MainDrawerHeader'

export const DashboardLayout = () => {
  const [barIsOpen, setBarIsOpen] = useState(false);

  const toggleMenu = () => {
    setBarIsOpen(!barIsOpen)
  }

  return (
    <>
      <AppTopBar open={barIsOpen} handleOpen={toggleMenu}/>
      <AppSideBar open={barIsOpen} handleOpen={toggleMenu}/>
      <AppMainContent open={barIsOpen}>
        <DrawerHeader />
        <Outlet/>
      </AppMainContent>
    </>
  )
}
