import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ERoles } from "../models/ERoles";
import Select from "@mui/material/Select";
import { useFormik } from "formik";
import { authService } from "../services";
import { IFormResult } from "../models/IFormResult";
import { BirthdayPicker } from "./BirthdayPicker";
import { userValidationSchema } from "../utils/validation.utils";

export const initialFormData = {
  phoneNumber: "",
  password: "",
  role: ERoles.USER,
  firstName: "",
  lastName: "",
  avatar: null,
  email: "",
  weight: 0,
  height: 0,
  birthday: "",
  userNote: "",
};

type CreateUserFormProps = {
  formSubmitted: (result: IFormResult) => void,
}

export const CreateUserForm: React.FC<CreateUserFormProps> = ({formSubmitted}) => {
  const [submitResult, setSubmitResult] = useState('');

  const createNewUser = async (formData: any) => {
    formik.setSubmitting(true)
    await authService.registerUser(formData)
      .then(resp => {
        if (resp.userData.id) {
          formik.resetForm();
          formik.setStatus('success');
        } else {
          formik.setStatus('error');
        }
      })
      .catch(err => {
        console.error(err)
        formik.setStatus('error');
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  }

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema: userValidationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      if (Object.keys(formik.errors).length === 0) {
        let valuesToWrite = Object.fromEntries(Object.entries(values).filter(([_, v]) => v && v != null));
        createNewUser(valuesToWrite);
      }
    }
  });

  return (
    <>
      {formik.status === undefined && <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth margin={"normal"}>
          <TextField
            required
            error={Boolean(formik.errors.phoneNumber)}
            label="Phone number"
            name="phoneNumber"
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
            helperText={formik.errors.phoneNumber}
          />
        </FormControl>

        <FormControl fullWidth margin={"normal"}>
          <InputLabel>Role</InputLabel>
          <Select
            value={formik.values.role}
            defaultValue={ERoles.USER}
            label="Role"
            name="role"
            onChange={formik.handleChange}
          >
            <MenuItem value={ERoles.USER} selected>
              User
            </MenuItem>
            <MenuItem value={ERoles.COACH}>Coach</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin={"normal"}>
          <TextField
            error={Boolean(formik.errors.password)}
            label="Password"
            name="password"
            type={"password"}
            onChange={formik.handleChange}
            value={formik.values.password}
            helperText={formik.errors.password}
          />
        </FormControl>

        <FormControl fullWidth margin={"normal"}>
          <TextField
            error={Boolean(formik.errors.firstName)}
            required
            label="First Name"
            name="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            helperText={formik.errors.firstName}
          />
        </FormControl>

        <FormControl fullWidth margin={"normal"}>
          <TextField
            error={Boolean(formik.errors.firstName)}
            required
            label="Last Name"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            helperText={formik.errors.lastName}
          />
        </FormControl>

        <FormControl fullWidth margin={"normal"}>
          <BirthdayPicker formik={formik}/>
        </FormControl>

        <FormControl fullWidth margin={"normal"}>
          <TextField
            label="User note"
            name="userNote"
            onChange={formik.handleChange}
            value={formik.values.userNote}
          />
        </FormControl>

        <FormControl fullWidth margin={"normal"}>
          <TextField
            label="Weight"
            name="weight"
            error={Boolean(formik.errors.weight)}
            helperText={formik.errors.weight}
            onChange={formik.handleChange}
            value={formik.values.weight}
          />
        </FormControl>

        <FormControl fullWidth margin={"normal"}>
          <TextField
            label="Height"
            name="height"
            error={Boolean(formik.errors.height)}
            helperText={formik.errors.height}
            onChange={formik.handleChange}
            value={formik.values.height}
          />
        </FormControl>

        <FormControl
          fullWidth
          margin={"normal"}
          sx={{
            gap: 2,
            flexFlow: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            type="submit"
            disabled={Object.keys(formik.errors).length > 0 || formik.isSubmitting}
          >
            Create user
          </Button>
        </FormControl>
      </form> }

      {formik.status === 'success' && <>
        <Typography variant={'subtitle1'} align={'center'}
          margin={4}>
          Congrats user created successfully!
        </Typography>
        <Button 
        variant={'contained'}
        color={'primary'}
        onClick={() => formSubmitted({success: true})} size={'large'}>
          Ok
        </Button>
      </>
      }
    </>
  );
};
