import React from 'react'
import { Box, Stack, TextField, Typography } from '@mui/material'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { IRangeDate } from '../models/IRangeDate'
import dayjs from "dayjs";

type RangeDatePickerProps = {
  dates: IRangeDate,
  onSelected: (dates: IRangeDate) => void,
  title?: string,
  small?: boolean,
  hideTitle?: boolean,
}

export const RangeDatePicker:React.FC<RangeDatePickerProps> = ({dates, onSelected, title, hideTitle, small}) => {
  return (
    <Box
        sx={{
          marginBottom: small ? 1 : 3,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction={"row"} alignItems={"center"} spacing={3}>
            {!hideTitle && <Box width={"calc(30% - 20px)"}>
              <Typography variant={"h4"}>{title ? title : 'Event range'}</Typography>
            </Box>}

            <DesktopDatePicker
              closeOnSelect
              label="From date"
              value={dates.from}
              minDate={dayjs().subtract(12, 'month').toDate()}
              maxDate={dayjs().toDate()}
              onChange={(newValue) => {
                onSelected({ ...dates, from: dayjs(newValue).toISOString() });
              }}
              renderInput={(params) => (
                <TextField size={small ? 'small' : 'medium'} {...params} helperText={null} />
              )}
            />

            <DesktopDatePicker
              closeOnSelect
              label="To date"
              value={dates.to}
              minDate={dayjs(dates.from).add(1, 'day').toDate()}
              maxDate={dayjs().toDate()}
              onChange={(newValue) => {
                onSelected({ ...dates, to: dayjs(newValue).toISOString() });
              }}
              renderInput={(params) => (
                <TextField size={small ? 'small' : 'medium'} {...params} helperText={null} />
              )}
            />
          </Stack>
        </LocalizationProvider>
      </Box>
  )
}
