import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import { Alert, Collapse, IconButton, Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Box,
  Grid,
  Typography, } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import bgImg from '../assets/login-bg.jpeg';
import { authService, userService } from '../services';
import { useDispatch } from 'react-redux';
import { setIsAuth, setUser } from '../store/user.slice';
import { axiosInstance } from '../services/axios.service';


function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://BioHackingLab.ru/">
        BioHackingLab
      </Link>
      {' 2022-2023'}
    </Typography>
  );
}

const theme = createTheme();

export const LoginPage = () => {
  const [formIsReady, setFormIsReady] = React.useState(false);
  const [formError, setFormError] = React.useState('');
  const dispatch = useDispatch();

  React.useEffect(() => {
    axiosInstance.get('/test');
  }, []);

  const handleFormChange = (event: React.FormEvent<HTMLFormElement>) => {
    const {value, name} = event.target as any;
    if (name === 'phoneNumber' && formError.length) {
      setFormError('');
    }

  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const phoneNumber = data.get('phoneNumber') as string;
    const password = data.get('password') as string;
    
    if (phoneNumber.length < 10) {
      return setFormError('Enter correct phone number')
    } if (password.length < 6) {
      return setFormError('Password must be more then 6 characters')
    }

    login(phoneNumber, password);
  };

  const login = async (phoneNumber: string, password: string) => {
    try {
      const baseUserData = await authService.logIn(phoneNumber, password)
      if (!baseUserData.access_token) {
        return alert(baseUserData.message);
      }
      localStorage.setItem('access_token', baseUserData.access_token);
      localStorage.setItem('access_token_expires_at', baseUserData.access_token_expires_at);
      localStorage.setItem('userId', baseUserData.userId);
      const userInfo = await userService.getUser(baseUserData.userId);
      dispatch(setIsAuth(true))
      dispatch(setUser(userInfo))
    } catch (error) {
      return alert('Something went wrong try again')
    }
  }

  return (
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url('+bgImg+')',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} onChange={handleFormChange} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="phoneNumber"
                label="Phone number"
                name="phoneNumber"
                autoComplete="tel"
                autoFocus
              />
              <Collapse in={formError.length > 0}>
                <Alert variant="filled" severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setFormError('');
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                >{formError}</Alert>
              </Collapse>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
  );
}
