import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IUser } from '../models/IUser'

export interface ClientState {
  client: IUser | null,
  clientsList: IUser[],
}

const initialState: ClientState = {
  client: null,
  clientsList: [],
}

export const userSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClient: (state, action: PayloadAction<IUser | null>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.client = action.payload;
    },
    setClients: (state, action: PayloadAction<IUser[]>) => {
      state.clientsList = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setClient, setClients } = userSlice.actions

export default userSlice.reducer