import { SvgIcon } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

export const CustomMealDot = (props: any) => {
  //console.log("CustomMealDot: props", props);  //DEBUG

  return (
    <>
    {props && props.value == "yes" &&  //props?.points?.length > 0 && props?.points?.[props.index]?.value >= 0 &&
    <SvgIcon {...props} x={props.cx - 6} y={/*props.cy - 6*/ 50} viewBox={"0 0 510 510"} width={'12px'} height={'12px'}>
      <path id="XMLID_308_" d="m250 120h30c0-19.541-6.941-29.954-12.52-38.32-4.504-6.758-7.48-11.221-7.48-21.68s2.976-14.922 7.48-21.68c5.579-8.366 12.52-18.778 12.52-38.32h-30c0 10.458-2.977 14.922-7.48 21.68-5.579 8.366-12.52 18.778-12.52 38.32s6.941 29.954 12.52 38.32c4.503 6.758 7.48 11.221 7.48 21.68z" fill="#000000" data-original="#000000"></path>
      <path id="XMLID_307_" d="m345 180h30c0-19.542-6.941-29.954-12.52-38.32-4.505-6.757-7.48-11.221-7.48-21.68s2.976-14.922 7.48-21.68c5.579-8.366 12.52-18.779 12.52-38.32h-30c0 10.459-2.976 14.922-7.48 21.68-5.579 8.366-12.52 18.779-12.52 38.32s6.941 29.954 12.52 38.32c4.504 6.758 7.48 11.222 7.48 21.68z" fill="#000000" data-original="#000000"></path>
      <path id="XMLID_306_" d="m135 180h30c0-10.458 2.977-14.922 7.481-21.68 5.578-8.366 12.519-18.778 12.519-38.32s-6.941-29.954-12.519-38.32c-4.504-6.758-7.481-11.221-7.481-21.68h-30c0 19.541 6.941 29.954 12.52 38.32 4.504 6.757 7.48 11.221 7.48 21.68s-2.977 14.922-7.48 21.68c-5.579 8.366-12.52 18.778-12.52 38.32z" fill="#000000" data-original="#000000"></path>
      <path id="XMLID_305_" d="m0 420v30h35.729l30 60h378.541l30-60h35.73v-30c-70.042 0-439.585 0-510 0z" fill="#000000" data-original="#000000"></path>
      <path id="XMLID_304_" d="m300 229.846v-4.846c0-24.813-20.186-45-45-45-24.861 0-45 20.135-45 45v4.846c-79.326 17.36-142.427 79.517-160.112 160.154h410.225c-17.677-80.595-80.749-142.785-160.113-160.154z" fill="#000000" data-original="#000000"></path>
    </SvgIcon>}
    </>
  )
}
