import React, { useEffect, useState } from "react";
import { Autocomplete, Button, Stack, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { IUser } from "../models/IUser";

export type AssignFormStatus = "success" | "error" | null;

type AssignFormProps = {
  formSubmitted: (updateData: boolean) => void;
  formType: "coach" | "client" | null;
  onSelected: (usersIds: number[]) => void;
  assignStatus: AssignFormStatus;
  availableIds: number[];
};

export const AssignForm: React.FC<AssignFormProps> = ({
  formSubmitted,
  onSelected,
  formType,
  assignStatus = null,
  availableIds,
}) => {
  const [availableUsers, setAvailableUsers] = useState<IUser[]>([]);
  const { clients, coaches } = useSelector((state: RootState) => state.user);

  const handleUserSelected = (e: any, v: IUser[], r: any) => {
    const ids = v.map((el) => +el.id);
    onSelected(ids);
  };

  
  const assignedUsers = (): IUser[] => {
    let users: any[];

    formType === "coach" ? users = coaches as any[] : users = clients as any[];
    
    if (availableIds === null) {
      return [];
    }
    return users.filter(el => el && availableIds.map(el => el.toString()).includes(el.id.toString()));
  }

  useEffect(() => {
    setAvailableUsers(assignedUsers())
  }, [availableIds]);

  return (
    <>
      {assignStatus === null && (
        <>
          {formType === "coach" && (
            <Autocomplete
              multiple
              options={coaches || []}
              value={availableUsers as any}
              getOptionLabel={(coach) => coach && `${coach?.firstName} ${coach?.lastName}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={"standard"}
                  label="Choose coaches for client"
                />
              )}
              onChange={handleUserSelected}
            />
          )}

          {formType === "client" && (
            <Autocomplete
              multiple
              options={clients || []}
              onChangeCapture={(e) => {
                // console.log(e)
              }}
              value={availableUsers as any}
              getOptionLabel={(client) =>
                `${client.firstName} ${client.lastName}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={"standard"}
                  label="Choose clients for coach"
                />
              )}
              onChange={handleUserSelected}
            />
          )}

          <Stack direction={"row"} gap={2} marginTop={2}>
            <Button
              variant={"text"}
              color={"primary"}
              onClick={() => formSubmitted(false)}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => formSubmitted(true)}
              fullWidth
            >
              Save
            </Button>
          </Stack>
        </>
      )}
      {assignStatus && <>
        <Typography variant={'h4'}>{assignStatus}</Typography>
        <Typography variant={'body1'} margin={4}>{
          assignStatus === 'error' ? 'Something went wrong please try again later.' : 'Operation Successfully complete'
        }</Typography>
        <Button
              variant={'contained'}
              color={"primary"}
              onClick={() => window.location.reload()}
              fullWidth
            >
              Ok
            </Button>
      </>}
    </>
  );
};
