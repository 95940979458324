import { IWorkout } from "../models/IWrokoutData";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { IRangeDate } from "../models/IRangeDate";
import { ITimelineRecord } from "../models/ITimelineRecord";
import { ETimelineRecordTypes } from "../models/ETimelineRecordTypes";
dayjs.extend(duration);
dayjs.extend(utc);

export const buildMetricBlocks = (
  metricsObject: any,
  excludedProps: string[]
): { label: string; value: string | number; prop: string }[] => {
  if (!metricsObject) return [];
  const data = [];
  for (let p in metricsObject) {
    if (p && metricsObject.hasOwnProperty(p)) {
      if (!excludedProps.includes(p)) {
        //@ts-ignore
        data.push({
          label: p.replace("_", " "),
          value: metricsObject[p],
          prop: p,
        });
      }
    }
  }
  return data;
};

export const getChangedValues = (values: any, initialValues: any) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = initialValues[key] !== value;

    if (hasChanged) {
      //@ts-ignore
      acc[key] = value;
    }

    return acc;
  }, {});
};

export const objectIsEmpty = (data: any) => Object.keys(data).length === 0;

export const getCorrectValueFormat = (value: number, label: string) => {
  if (!value) return "N/A";

  const cgmValues = [
    "value",
  ]
  const mealValues = [
    "proteins",
    "carbs",
    "fats",
  ];
  const dateTimeValues = [
    "bedtime_start",
    "bedtime_stop",
    "time_start",
    "time_end",
    "date",
  ];
  const bpmValues = [
    "average_hr",
    "max_hr",
    "hr_lowest",
    "hr_average",
    "respiratory_rate",
  ];
  const meterValues = [
    "distance",
    "total_elevation_gain",
    "elev_high",
    "elev_low",
    "daily_movement",
  ];
  const wattValues = [
    "average_watts",
    "device_watts",
    "max_watts",
    "weighted_average_watts",
  ];
  const speedValues = ["average_speed", "max_speed"];
  const caloriesValues = ["calories_total", "calories_active", "calories"];
  const minutesValues = ["low", "medium", "high"];
  const nonSecondsValues = [
    ...bpmValues,
    ...meterValues,
    ...wattValues,
    ...speedValues,
    ...caloriesValues,
    ...minutesValues,
    ...dateTimeValues,
    ...mealValues,
    ...cgmValues,
    "steps",
    "score",
    "average_hrv",
    "efficiency",
    "temperature_delta",
  ];
  label = label.replace(" ", "_");

  let outputData;

  if (!nonSecondsValues.includes(label)) {
    const curVal = dayjs.duration({ seconds: value }).asHours();
    outputData =
      +curVal > 1
        ? `${curVal.toFixed(1)}h`
        : `${dayjs.duration({ seconds: value }).asMinutes().toFixed(1)} m`;
  } else {
    if (bpmValues.includes(label)) {
      outputData = value + " bpm";
    } else if (wattValues.includes(label)) {
      outputData = value + " watts";
    } else if (meterValues.includes(label)) {
      outputData = value + " meters";
    } else if (speedValues.includes(label)) {
      outputData = value + " meters/sec";
    } else if (label === "efficiency") {
      outputData = value + " %";
    } else if (label === "average_hrv") {
      outputData = value + " rmssd";
    } else if (caloriesValues.includes(label)) {
      outputData = value + " kCal";
    } else if (minutesValues.includes(label)) {
      const curVal = dayjs.duration({ minutes: value }).asHours();
      outputData = `${curVal.toFixed(1)}h`;
    } else if (dateTimeValues.includes(label)) {
      outputData = `From ${dayjs(value).format('HH:mm')} To ${dayjs(value).format('HH:mm')}`
    } else if (mealValues.includes(label)) {
      outputData = value + " g"
    } else {
      outputData = value;
    }
  }
  return outputData;
};

export const getDates = (from?: Date, to?: Date): IRangeDate => {
  const fromData = from ? dayjs(from).startOf('day').toISOString() : dayjs().startOf('day').toISOString()
  const toDate = to ? dayjs(to).endOf('day').toISOString() : null;
  return {from: fromData, to: toDate}
}

export const getAverage = (array: any[]) => {
  const temp: any = {};
  const count: any = {};
  for (let i = 0; i < array.length; i++) {
    const object = array[i];
    for (const key in object) {
      if (typeof count[key] !== "number") {
        count[key] = 1;
      } else {
        count[key]++;
      }
      const value = object[key];
      if (typeof temp[key] !== "number") {
        temp[key] = typeof value !== "number" ? 0 : value;
      } else {
        temp[key] += typeof value !== "number" ? 0 : value;
      }
    }
  }

  const result: any = {};
  for (const k in temp) {
    const total = temp[k];
    const totalObjects = count[k];
    result[k] = total / totalObjects;
  }
  return result;
};

export const getAverageForData = (objectsArr: any[]) => {
  const sumCountObject = _getSumCountObject(objectsArr)
  const averageObject: any = {}
  for (const prop in sumCountObject) {
    const [sum, count] = sumCountObject[prop]
    averageObject[prop] = (sum / count).toFixed(2)
  }
  return averageObject
}

function _getSumCountObject(objectsArray: any) {
  const sumCountObject: any = {}

  for (const obj of objectsArray) {
    for (const prop in obj) {
      if (!(prop in sumCountObject)) {
        sumCountObject[prop] = [Number(obj[prop]), 1]
      } else {
        const [curSum, curCount] = sumCountObject[prop]
        sumCountObject[prop] = [curSum + Number(obj[prop]), curCount + 1]
      }
    }
  }
  return sumCountObject
}

export const mapTimelinerecordsForChart = (records: ITimelineRecord[], timestamp?: boolean) => {
  const mappedRecords = records.map((el, i) => {
    if (
      el.type === ETimelineRecordTypes.Meal ||
      el.type === ETimelineRecordTypes.Mood ||
      el.type === ETimelineRecordTypes.Note
    ) {
      return {
        record: !timestamp ? 120 : 8, // this is just a fake value to appear dots on chart
        type: el.type,
        // date: el.date,
        date: !timestamp ? el.date : dayjs.utc(el.date).valueOf(),
        recordData: {
          proteins: el.proteins,
          carbs: el.carbs,
          fats: el.fats,
          message: el.message,
          title: el.title,
        },
      };
    }
  });
  return mappedRecords
}