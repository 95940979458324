import { ITimelineRecord } from "../models/ITimelineRecord";
import { getDates } from "../utils/common.utils";
import { axiosInstance } from "./axios.service";

const controller = 'timeline';

export const getUserTimelineData = (userId: number | string, dataReq?: any): Promise<ITimelineRecord[]> => {
  const {from, to} = getDates(dataReq?.from as Date, dataReq?.to as Date);
  return axiosInstance.get(`${controller}/${userId}`, {params: {
    startDate: from,
    endDate: to
  }});
}

export const createTimelineRecord = (userId: number | string, recordData: ITimelineRecord): Promise<any> => {
  const bodyFormData = new FormData();
  if (recordData.pictures && recordData.pictures?.length > 0) {
    bodyFormData.append('pictures', recordData.pictures[0]);
  }
  for (const p in recordData) {
    if (p && recordData.hasOwnProperty(p) && p !== 'pictures') {
      //@ts-ignore
      bodyFormData.append(p, recordData[p]);
    }
  }

  return axiosInstance.post(`${controller}/${userId}/record`, bodyFormData);
}

export const updateRecord = (recordId: number, recordData: ITimelineRecord): Promise<ITimelineRecord> => {
  const bodyFormData = new FormData();
  if (recordData.pictures && recordData.pictures?.length > 0) {
    if (typeof recordData.pictures[0] !== 'string') {
      bodyFormData.append('pictures', recordData.pictures[0]);
    }
  }
  for (const p in recordData) {
    if (p && recordData.hasOwnProperty(p) && !['pictures','userId','id','createdAt','updatedAt'].includes(p)) {
      //@ts-ignore
      bodyFormData.append(p, recordData[p]);
    }
  }
  return axiosInstance.put(`${controller}/${recordId}`, bodyFormData);
}

export const deleteRecord = (recordId: number, dataReq?: any):Promise<any> => {
  return axiosInstance.delete(`${controller}/${recordId}`);
}