import React, { useEffect, useState } from "react";
import {
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  CartesianGrid,
} from "recharts";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ISleepStreamMetric, ISleepStrem } from "../models/ISleepData";
import { LinearProgress } from "@mui/material";
dayjs.extend(duration);
dayjs.extend(utc);

export type SleepStreamChartProps = {
  data: ISleepStrem;
  range?: number[];
  loaded: boolean;
};

export type StreamDataType = {
  id: string;
  data: ISleepStreamMetric[];
  color: string;
}

const CustomTick = (props: any) => {
  return <span>{dayjs(props.payload.value).format("HH:mm A")}</span>;
};

const CustomLegend = (props: any, some: any) => {
  return (
    <>
      {props.payload.map((el: any) => (
        <>{el.value}</>
      ))}
    </>
  );
};

export const SleepStreamChart: React.FC<SleepStreamChartProps> = ({
  data,
  loaded,
  range,
}) => {
  const [streamData, setStreamData] = useState<StreamDataType[]>([]);

  useEffect(() => {
    setStreamData(
      [
        {
          id: "hrv",
          data: data["hrv"],
          color: "#3b82a2",
        },
        {
          id: "heartrate",
          data: data["heartrate"],
          color: "#efca70",
        },
        {
          id: "hypnogram",
          data: data["hypnogram"],
          color: "#6a4f74",
        },
        {
          id: "respiratory_rate",
          data: data["respiratory_rate"],
          color: "#a9d674",
        },
      ]
    )
  }, [data])

  return (
    <>
      {!data.hrv.length &&
        !data.heartrate.length &&
        !data.hypnogram.length &&
        !data.respiratory_rate.length &&
        loaded && <>No chart without data</>}
      {(data.hrv.length > 0 ||
        data.heartrate.length > 0 ||
        data.hypnogram.length > 0 ||
        (data.respiratory_rate.length > 0 && loaded)) && (
        <ResponsiveContainer width={"100%"} height={480}>
          <LineChart
            height={480}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <Tooltip
              formatter={(value: string, name: any, { payload }: any) => {
                return `${value} ${payload.unit}`;
              }}
              labelFormatter={(label: any, payload: any) => {
                return dayjs.utc(label).format("MMM D, HH:mm A");
              }}
            />

            {streamData.map((el, i) => {
              return (
                <Line
                  type={"monotone"}
                  name={el.id}
                  data={el.data}
                  key={el.id}
                  dataKey={"value"}
                  stroke={el.color}
                  connectNulls={false}
                />
              );
            })}

            <Legend />

            <XAxis
              dataKey={"timestamp"}
              allowDuplicatedCategory={false}
              tick={{
                fontSize: 12,
              }}
              tickFormatter={(value: string, some: any) => {
                return dayjs.utc(value).format("HH:mm A");
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
