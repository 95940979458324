import React from 'react'
import styled from "@emotion/styled";

export const MetricsHolder = styled.div({
  width: "100%",
  height: "100%",
  display: "flex",
  flexFlow: "row wrap",
  alignItems: "flex-start",
  justifyContent: "space-between",
  gap: 16,
  marginBottom: 20,
});

export const ChartsHolder = styled(MetricsHolder)`
  ${{
    [".innerCard"]: {
      padding: 10,
      width: "100%",
      overflow: 'visible'
      // flex: "1 1 calc(50% - 10px)",
    },
  }}
`;

export const CommonChart = () => {
  return (
    <div>CommonChart</div>
  )
}
