import { Axios } from "axios";
import { BASE_API_URL } from "../constants";

export const axiosInstance = new Axios({
  baseURL: BASE_API_URL,
});
axiosInstance.interceptors.response.use(
  (response) => {
    try {
      return JSON.parse(response.data);
    } catch (error) {
      return response.data
    }
  },
  (error) => {
    // if (error && error.response?.status === 403) {
    //   // refreshToken();
    // }
    // return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  (request) => {
    if (!request.url?.includes('auth')) {
      const token = localStorage.getItem('access_token');
      if (!token) return request;
      request.headers = {
        ...request.headers,
        'Authorization': `Bearer ${token}`
      }
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
