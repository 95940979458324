import React, { useEffect, useState } from "react";
import { Card, Typography } from "@mui/material";
import { ChartsHolder } from "./CommonChart";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ReferenceLine,
  ReferenceArea,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { CustomMealDot } from "./CustomMealDot";


export type DailyDataProps = {
  dailyData: any[];
};

export const DailyDataTab: React.FC<DailyDataProps> = ({ dailyData }) => {
  return (
    <>
      <ChartsHolder>
        <Card className={"innerCard"}>
          <Typography variant={"subtitle1"} sx={{ textTransform: "capitalize", fontWeight: 600, }}>
            Daily stream - 2023-07-24 mon
          </Typography>

         <ResponsiveContainer width={"100%"} height={480}>
           <LineChart width={700} height={480} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
             <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
             <XAxis dataKey="name" />
             <YAxis yAxisId="left" />
             <YAxis yAxisId="right" orientation="right" />
             <Tooltip />
             <Legend />
             <Line yAxisId="left" type="monotone" dataKey="steps" stroke={colors[0]} />
             <Line yAxisId="left" type="monotone" dataKey="bpm" stroke={colors[1]} activeDot={{ r: 8 }} />
             <Line yAxisId="right" type="monotone" dataKey="cgm" stroke={colors[2]} />
             <Line yAxisId="left"
               dataKey="meal"
               dot={(props) => <CustomMealDot {...props} />}
               activeDot={(props) => <CustomMealDot {...props} />}
               connectNulls={false}
               strokeWidth={0}
               stroke={"coral"}
               />
             <ReferenceArea x1={"6:30"} x2={"8:00"} yAxisId="left" stroke="red" label="training" />
           </LineChart>
         </ResponsiveContainer>
        </Card>
      </ChartsHolder>
    </>
  );
};


const data = [
  { name: '22:00', bpm: 81, steps: 208, cgm: 5.0 },
  { name: '22:30', bpm: 82, steps: 200, cgm: 5.1 },
  { name: '23:00', bpm: 80, steps: 100, cgm: 5.4 },
  { name: '23:30', bpm: 65, steps: 0, cgm: 4.8 },
  { name: '0:00', bpm: 61, steps: 0, cgm: 4.6 },
  { name: '0:30', bpm: 61, steps: 0, cgm: 4.4 },
  { name: '1:00', bpm: 62, steps: 0, cgm: 4.3 },
  { name: '1:30', bpm: 62, steps: 0, cgm: 4.3 },
  { name: '2:00', bpm: 61, steps: 0, cgm: 4.3 },
  { name: '2:30', bpm: 62, steps: 0, cgm: 4.3 },
  { name: '3:00', bpm: 61, steps: 0, cgm: 4.2 },
  { name: '3:30', bpm: 61, steps: 0, cgm: 4.2 },
  { name: '4:00', bpm: 61, steps: 0, cgm: 4.2 },
  { name: '4:30', bpm: 62, steps: 0, cgm: 4.2 },
  { name: '5:00', bpm: 61, steps: 0, cgm: 4.2 },
  { name: '5:30', bpm: 62, steps: 0, cgm: 4.2 },
  { name: '6:00', bpm: 70, steps: 150, cgm: 4.6, meal: "yes" },
  { name: '6:30', bpm: 76, steps: 300, cgm: 5.0 },
  { name: '7:00', bpm: 90, steps: 350, cgm: 5.1 },  // train
  { name: '7:30', bpm: 101, steps: 200, cgm: 5.1 },  // train
  { name: '8:00', bpm: 88, steps: 250, cgm: 5.4 },
  { name: '8:30', bpm: 80, steps: 100, cgm: 5.3 },
  { name: '9:00', bpm: 81, steps: 200, cgm: 5.1 },
];

const colors = [
  "#F44336",
  "#1A237E",
  "#9C27B0",
  "#E91E63",
  "#2196F3",
  "#00BCD4",
  "#4CAF50",
  "#CDDC39",
  "#3F51B5",
  "#FF9800",
  "#FF5722",
  "#FFEB3B",
];
