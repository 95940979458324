import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IUser } from '../models/IUser'

export interface UserState {
  user: IUser | null,
  isAuth: boolean,
  coaches?: IUser[],
  clients?: IUser[],
}

const initialState: UserState = {
  user: null,
  isAuth: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.user = action?.payload || null;
    },
    setIsAuth: (state, action) => {
      state.isAuth = action.payload
    },
    setCoachesList: (state, action) => {
      state.coaches = action.payload
    },
    setClientsList: (state, action) => {
      state.clients = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUser, setIsAuth, setCoachesList, setClientsList } = userSlice.actions

export default userSlice.reducer