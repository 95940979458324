import React, { useState } from "react";
import { filter } from "lodash";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { UserListToolbar } from "./UsersListToolbar";
import { UserListHead } from "./UsersListHead";
import dayjs from "dayjs";
import { UserOptionsMenu } from "./UserOptionsMenu";
import { EUserMenuActions } from "../models/EUserMenuActions";
import { openConfirmationModal } from "../store/info.slice";
import { BasicModal } from "./BasicModal";
import { AssignForm, AssignFormStatus } from "./AssignForm";
import { ERoles } from "../models/ERoles";
import { userService } from "../services";
import { useDispatch } from "react-redux";

interface Data {
  lastName: string;
  firstName: string;
  id: string;
  created: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if ((b && b[orderBy]) < (a && a[orderBy])) {
    return -1;
  }
  if ((b && b[orderBy]) > (a && a[orderBy])) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
  query: string
) {
  if (!array?.length) return [];

  const stabilizedThis = array.map((el: any, index: number) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    //@ts-ignore
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user: any) =>
        (_user.filterName + " " + _user.lastName)
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el: any) => el[0]);
}

type UserTableProps = {
  usersList: any[];
  pageChange: (pageId: number) => void;
};

const TABLE_HEAD = [
  { id: "firstName", label: "First Name", alignRight: false },
  { id: "lastName", label: "Last Name", alignRight: false },
  { id: "role", label: "Role", alignRight: false },
  { id: "createdAt", label: "Created", alignRight: false },
  { id: "options", label: "Edit", alignRight: true },
];

export const UsersTable: React.FC<UserTableProps> = ({ usersList }) => {
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<Order>("asc");
  const [selected, setSelected] = useState<any[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [orderBy, setOrderBy] = useState<string>("name");
  const [filterName, setFilterName] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [assignMode, setAssignMode] = useState<EUserMenuActions | null>(null);
  const [assignedUsersIds, setAssignedUsersIds] = useState<number[]>([]);
  const [asignedSuccess, setAsignedSuccess] = useState<AssignFormStatus>(null);
  const [deleteUserMode, setDeleteUserMode] = useState(false);
  const [deleteUserSuccess, setDeleteUserSuccess] = useState<
    boolean | undefined
  >(undefined);

  const nav = useNavigate();
  const dispatch = useDispatch();

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event: any) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - usersList?.length || 0)
      : 0;

  const filteredUsers = applySortFilter(
    usersList,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const handleActionMenuClicked = (
    actionType: EUserMenuActions | null,
    userId: number
  ) => {
    if (!actionType) return;

    if (actionType === EUserMenuActions.EDIT) {
      setSelectedUserId(userId);
      return nav(`/dashboard/users/${userId}?editMode=true`, { state: {userId}});
    }

    const [user] = usersList.filter((u) => u && u.id === userId);

    if (actionType === EUserMenuActions.ASSIGN_CLIENT) {
      setAssignedUsersIds(user.clientIds);
    } else if (actionType === EUserMenuActions.ASSIGN_COACH) {
      setAssignedUsersIds(user.coachIds);
    }
    if (
      actionType === EUserMenuActions.ASSIGN_CLIENT ||
      actionType === EUserMenuActions.ASSIGN_COACH
    ) {
      setSelectedUserId(userId);
      setAsignedSuccess(null);
      setAssignMode(actionType);
    }

    if (actionType === EUserMenuActions.DELETE) {
      setSelectedUserId(userId);
      setDeleteUserMode(true);
    }

  };

  const handleRowClick = (userId: number) => {
    setSelectedUserId(userId);
    nav("/dashboard/users/" + userId, { state: { userId } });
  };

  const handleConfirmDelete = (confirm: boolean) => {
    if (confirm && selectedUserId) {
      return userService
        .deleteUser(+selectedUserId)
        .then((resp) => {
          setDeleteUserSuccess(!Boolean(resp.status && [400, 404].includes(resp.status)));
        })
        .catch((err) => {
          setDeleteUserSuccess(false);
        });
    } else {
      handleDeleteOperationEnd();
    }
  };

  const handleAssignFormSubmitted = (saveAssignment: boolean) => {
    if (!saveAssignment) {
      setAssignMode(null);
      setSelectedUserId(null);
      setAssignedUsersIds([]);
      setAsignedSuccess(null);
      return;
    }

    return userService
      .updateAssignments(
        selectedUserId as number,
        assignedUsersIds,
        assignMode === EUserMenuActions.ASSIGN_CLIENT ? "clientIds" : "coachIds"
      )
      .then((resp: any) => {
        setAsignedSuccess( resp?.statusCode ? "error" : "success");
      })
      .catch((_) => setAsignedSuccess("error"));
  };

  const handleDeleteOperationEnd = () => {
    setDeleteUserMode(false);
    setDeleteUserSuccess(undefined);
    setSelectedUserId(null);
  };

  return (
    <>
      <Card sx={{ padding: 2 }}>
        <UserListToolbar
          filterName={filterName}
          onFilterName={handleFilterByName}
        />

        {/* <Scrollbar> */}
        {usersList && (
          <TableContainer sx={{ minWidth: 800 }}>
            <Table size={"small"}>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={usersList.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any) => {
                    const {
                      id,
                      firstName,
                      lastName,
                      avatarUrl,
                      createdAt,
                      role,
                    } = row;
                    const isItemSelected = selected.indexOf(firstName) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        onClick={() => handleRowClick(id)}
                      >
                        <TableCell scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar
                              src={avatarUrl}
                              sx={{ width: 24, height: 24 }}
                            />
                            <Typography variant="subtitle2" noWrap>
                              {firstName}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {lastName}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Chip
                            size={"small"}
                            label={role.toLowerCase()}
                            color={
                              role === ERoles.ADMIN
                                ? "error"
                                : role === ERoles.USER
                                ? "success"
                                : "info"
                            }
                          />
                        </TableCell>
                        <TableCell align="left">
                          {dayjs(createdAt).format("DD.MM.YYYY HH:mm")}
                          {/* <Chip label="primary" color={status === 'banned' ? 'error' : 'success'} >
                              {status.toLowerCase()}
                            </Chip> */}
                        </TableCell>

                        <TableCell align="right">
                          <UserOptionsMenu
                            userRole={role}
                            onActionClicked={(actionType) =>
                              handleActionMenuClicked(actionType, id)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
              </TableBody>

              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      not found
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}

        {!usersList && <Typography padding={4}>Loading...</Typography>}
        {/* </Scrollbar> */}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={usersList?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <BasicModal
        open={Boolean(assignMode)}
        onClose={() => setAssignMode(null)}
        title={
          assignMode === EUserMenuActions.ASSIGN_CLIENT
            ? "Assign clients to coach"
            : "Assign coach to client"
        }
      >
        <AssignForm
          assignStatus={asignedSuccess}
          formSubmitted={handleAssignFormSubmitted}
          formType={
            assignMode === EUserMenuActions.ASSIGN_CLIENT ? "client" : "coach"
          }
          availableIds={assignedUsersIds}
          onSelected={setAssignedUsersIds}
        />
      </BasicModal>

      <BasicModal
        open={deleteUserMode}
        onClose={() => setDeleteUserMode(false)}
        title={"are you sure?"}
      >
        {deleteUserSuccess === undefined && (
          <>
            <Typography marginTop={4} marginBottom={4}>
              {"You are about to delete this user. Do you confirm?"}
            </Typography>
            <Stack direction={"row"} gap={2}>
              <Button
                fullWidth
                variant={"text"}
                color={"info"}
                onClick={() => handleConfirmDelete(false)}
                size={"large"}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                variant={"contained"}
                color={"info"}
                onClick={() => handleConfirmDelete(true)}
                size={"large"}
              >
                Acknowlage
              </Button>
            </Stack>
          </>
        )}
        {deleteUserSuccess !== undefined && deleteUserSuccess && (
          <>
            <Typography marginTop={4} marginBottom={4}>
              {"User was delete successfully"}
            </Typography>
            <Stack direction={"row"} gap={2}>
              <Button
                fullWidth
                variant={"text"}
                color={"info"}
                onClick={handleDeleteOperationEnd}
                size={"large"}
              >
                Ok
              </Button>
            </Stack>
          </>
        )}

        {deleteUserSuccess !== undefined && !deleteUserSuccess && (
          <>
            <Typography marginTop={4} marginBottom={4}>
              {"Something went wrong please try again later"}
            </Typography>
            <Stack direction={"row"} gap={2}>
              <Button
                fullWidth
                variant={"text"}
                color={"info"}
                onClick={handleDeleteOperationEnd}
                size={"large"}
              >
                Ok
              </Button>
            </Stack>
          </>
        )}
      </BasicModal>
    </>
  );
};
