import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IRangeDate } from "../models/IRangeDate";
import dayjs from "dayjs";
import { ETimelineRecordTypes } from "../models/ETimelineRecordTypes";
import { IRecordCategory } from "../models/IRecordCategory";
import { ITimelineRecord } from "../models/ITimelineRecord";

const initialDates = {
  from: dayjs().subtract(1, "day").startOf("day").toISOString(),
  to: dayjs().endOf("day").toISOString(),
};

const initialRecordCategories = [
  { label: "All", type: null, active: true },
  { label: "🍽 Meal", type: ETimelineRecordTypes.Meal, active: false },
  { label: "🗒 Notes", type: ETimelineRecordTypes.Note, active: false },
  { label: "🙂 Mood", type: ETimelineRecordTypes.Mood, active: false },
  { label: "🩺 Symptoms", type: ETimelineRecordTypes.Symptom, active: false },
];

export interface IConfirmationDialog {
  title?: string;
  message?: string;
  open?: boolean;
  result?: { confirmed: boolean } | undefined | null;
  modalType: "userDelete" | "recordDelete" | null;
}
export interface InfoState {
  devMode: boolean;
  sleepRangePicker: IRangeDate;
  workoutRangePicker: IRangeDate;
  activityRangePicker: IRangeDate;
  cgmRangePicker: IRangeDate;
  timelineRangePicker: IRangeDate;
  confirmationModal: IConfirmationDialog;
  recordCategories: IRecordCategory[];
  timelineRecords: ITimelineRecord[];
  timelineRecordsLoaded: boolean;
}

const initialState: InfoState = {
  devMode: false,
  sleepRangePicker: { ...initialDates },
  workoutRangePicker: { ...initialDates },
  activityRangePicker: { ...initialDates },
  cgmRangePicker: { ...initialDates },
  timelineRangePicker: { ...initialDates },
  recordCategories: [...initialRecordCategories],
  timelineRecords: [],
  confirmationModal: {
    modalType: null,
  },
  timelineRecordsLoaded: false,
};

export const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    setDevMode: (state, action: PayloadAction<boolean>) => {
      state.devMode = action.payload;
    },
    setPickerData: (state, action: PayloadAction<{
        pickerType: "sleep" | "workout" | "activity" | "cgm" | "timeline";
        dates: IRangeDate;
      }>
    ) => {
      const currentState = state[`${action.payload.pickerType}RangePicker`];
      const newDates = Object.assign({}, { ...currentState, ...action.payload.dates });
      state[`${action.payload.pickerType}RangePicker`] = newDates;
    },
    setCurrentRecordCategory: (state, action: PayloadAction<ETimelineRecordTypes | null>) => {
      state.recordCategories = [
        ...initialRecordCategories.map((el) => ({
          ...el,
          active: el.type === action.payload,
        })),
      ];
    },
    setTimelineRecords: (state, action: PayloadAction<ITimelineRecord[]>) => {
      const mappedRecords = action.payload.map(el => {
        el = {...el, date: dayjs(el.date).format()}
        return el;
      })
      state.timelineRecordsLoaded = true;
      state.timelineRecords = [...mappedRecords];
    },
    setTimelineRecordsLoaded: (state, action: PayloadAction<boolean>) => {
      state.timelineRecordsLoaded = action.payload;
    },
    openConfirmationModal: (state, action: PayloadAction<IConfirmationDialog>) => {
      state.confirmationModal = {
        ...state.confirmationModal,
        ...action.payload,
        open: true,
      };
    },
    closeConfirmationModal: (
      state,
      action?: PayloadAction<{ confirmed: boolean } | undefined | null>
    ) => {
      // state.confirmationModal = {...initialState.confirmationModal};
      state.confirmationModal.result = action?.payload || null;
      state.confirmationModal.open = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDevMode,
  setPickerData,
  openConfirmationModal,
  closeConfirmationModal,
  setCurrentRecordCategory,
  setTimelineRecords,
  setTimelineRecordsLoaded,
} = infoSlice.actions;

export default infoSlice.reducer;
