import React, { useCallback, useEffect, useState } from "react";
import { Card, Typography, Box } from "@mui/material";
import {
  buildMetricBlocks,
  getAverageForData,
  getCorrectValueFormat,
  mapTimelinerecordsForChart,
} from "../utils/common.utils";
import { IWorkout } from "../models/IWrokoutData";
import { RangeDatePicker } from "./RangeDatePicker";
import { IRangeDate } from "../models/IRangeDate";
import { vitalService } from "../services";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { mockWorkoutSummaryData } from "../utils/mock.utils";
import { setPickerData } from "../store/info.slice";
import { SummaryChart } from "./SummaryChart";
import { ChartsHolder, MetricsHolder } from "./CommonChart";
import { ITimelineRecord } from "../models/ITimelineRecord";
import dayjs from "dayjs";

export const getWorkoutDataBlocks = (dataObject: any) => {
  return buildMetricBlocks(dataObject, [
    "user_key",
    "timezone_offset",
    "time_start",
    "time_end",
    "user_id",
    "user_key",
    "records",
    "hr_zones",
    "title",
    "id",
    "calories",
    "sport",
    "source",
    "map",
    "summary_polyline",
    "provider_id",
  ]);
};

export type WorkoutTabDataProps = {
  workoutData: IWorkout[];
};

export const WorkoutDataTab = ({ workoutData }: WorkoutTabDataProps) => {
  const client = useSelector((state: RootState) => state.client.client);
  const [dataProviders, setDataProviders] = useState<string[]>([]);
  const [blocks, setBlocks] = useState<any[]>([]);
  const [workoutSumary, setWorkoutSummary] = useState<IWorkout[]>(workoutData);
  const { workoutRangePicker } = useSelector(
    (state: RootState) => state.info
  );
  const dispatch = useDispatch();
  const {timelineRecords} = useSelector((state: RootState) => state.info);

  const handleWorkoutsRangePickerChanged = (dates: IRangeDate) => {
    dispatch(setPickerData({ pickerType: "workout", dates }));
  };

  const loadWorkoutData = async (newDates: IRangeDate) => {
    if (!client?.vitalUserId) return;

    try {
      const data = await vitalService.getWorkoutsSummary(
        client?.vitalUserId as string,
        newDates
      );

      const providersInData = [
        ...new Set(data.workouts.map((el: any) => el && el.source.slug)),
      ];

      const mergedData = _getMergedData(data.workouts, timelineRecords);
      _loadBlocks(mergedData);

      setDataProviders(providersInData);
    } catch (error) {
      setBlocks(getWorkoutDataBlocks({}));
      setWorkoutSummary([]);
      setDataProviders([]);
    }
  };

  useEffect(() => {
    loadWorkoutData(workoutRangePicker);
  }, [workoutRangePicker]);

  const getLabel = useCallback((value: number | string, label: string) => {
    return getCorrectValueFormat(+value, label);
  }, []);

  useEffect(() => {
    if (workoutData && workoutData.length) {
      const mergedData = _getMergedData(workoutData, timelineRecords);
      _loadBlocks(mergedData);
    }
  }, [workoutData]);

  const _loadBlocks = (blocksData: any) => {
    const avgData = getAverageForData(blocksData);
    setBlocks(getWorkoutDataBlocks(avgData));
    setWorkoutSummary(blocksData as any);
  };

  const _getMergedData = (workoutData: IWorkout[], recordsData: ITimelineRecord[]) => {
    const mappedRecords = mapTimelinerecordsForChart(recordsData);
    return workoutData.map((el) => {
      el.records = [];
      for (const r of mappedRecords) {
        if (
          r &&
          dayjs
            .utc(el.time_start)
            .utcOffset(el.timezone_offset / 60)
            .isSame(r.date, "day")
        ) {
          el.records.push(r);
          el.record = r.record;
        }
      }
      return el;
    });
  }

  return (
    <>
      <RangeDatePicker
        dates={workoutRangePicker}
        onSelected={handleWorkoutsRangePickerChanged}
        title={"Workouts"}
      />
      {workoutSumary && (
        <>
          <MetricsHolder>
            {blocks.map((el: any, i: number) => (
              <Card
                sx={{
                  padding: 1,
                  flex: "1 1 calc(25% - 16px)",
                }}
                key={i}
              >
                <Typography
                  variant={"subtitle1"}
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 600,
                  }}
                >
                  {el.label
                    .toLowerCase()
                    .replace("_", " ")
                    .replace("average", "avg.")}
                </Typography>
                <Typography>{getLabel(el.value, el.label)}</Typography>
              </Card>
            ))}
          </MetricsHolder>

          <ChartsHolder>
            <Card className={"innerCard"}>
              <Typography
                variant={"subtitle1"}
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                }}
              >
                Workouts summary chart
              </Typography>
              <Typography variant={"subtitle2"}>
                {dataProviders.length > 0 && (
                  <>
                    Based on providers:{" "}
                    {dataProviders.map((el: any, i) => (
                      <Box
                        component={"span"}
                        key={i}
                        sx={{
                          border: 1,
                          padding: "0 5px",
                          borderRadius: 2,
                          marginRight: 1,
                          fontSize: 12,
                        }}
                      >
                        {el}
                      </Box>
                    ))}
                  </>
                )}
              </Typography>
              <SummaryChart
                data={workoutSumary}
                metrics={blocks}
                dateKey={"time_start"}
                chartType={'workout'}
              />
            </Card>
          </ChartsHolder>
        </>
      )}
    </>
  );
};
