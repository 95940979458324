import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import * as yup from "yup";
import { useFormik } from "formik";
import { ITimelineRecord } from "../models/ITimelineRecord";
import { ETimelineRecordTypes } from "../models/ETimelineRecordTypes";
import { timelineService, userService } from "../services";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { ImageOutlined } from "@mui/icons-material";
import { IFormResult } from "../models/IFormResult";

const initialFormData: ITimelineRecord = {
  type: ETimelineRecordTypes.Note,
  message: "",
  title: "",
  pictures: [],
  date: dayjs().toDate(),
  proteins: "",
  carbs: "",
  fats: "",
};

export type CreateTimelineRecordFormProps = {
  formSubmitted: (data?: IFormResult & { recordData: any }) => void;
  recordData?: ITimelineRecord | null;
};

export const CreateTimelineRecordForm: React.FC<
  CreateTimelineRecordFormProps
> = ({ formSubmitted, recordData }) => {
  const hiddenImagesFieldRef = useRef<any>(null);
  const [newRecord, setNewRecord] = useState<ITimelineRecord | null>(null);
  const userId = useSelector((state: RootState) => state.client.client?.id);

  const validationSchema = yup.object({
    message: yup.string().min(3),
    proteins: yup.number(),
    carbs: yup.number(),
    fats: yup.number(),
  });

  const handleFormSubmitted = () => {
    formSubmitted({ success: true, recordData: newRecord });
  };

  const formik = useFormik({
    initialValues: recordData || initialFormData,
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      if (typeof values.date !== "string") {
        values.date = dayjs(values.date).toISOString() as any;
      }
      if (recordData) {
        // we need to update record
        timelineService
          .updateRecord(recordData.id as number, values)
          .then((resp) => {
            if (resp.id) {
              setNewRecord(resp);
              formik.setStatus("success");
            } else {
              formik.setStatus("error");
            }
          })
          .finally(() => formik.setSubmitting(false));
      } else {
        // we need to create record
        timelineService
          .createTimelineRecord(userId as number, values)
          .then((resp) => {
            if (resp.id) {
              setNewRecord(resp);
              formik.setStatus("success");
            } else {
              formik.setStatus("error");
            }
          })
          .finally(() => formik.setSubmitting(false));
      }
    },
  });

  const handlePicturesChanged = (e: any) => {
    formik.setFieldValue("pictures", e.currentTarget.files);
  };

  return (
    <>
      {formik.status !== "success" && (
        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth margin={"normal"}>
            <TextField
              required
              error={Boolean(formik.errors.message)}
              label="Message"
              name="message"
              onChange={formik.handleChange}
              value={formik.values.message}
              helperText={formik.errors.message}
            />
          </FormControl>

          <FormControl fullWidth margin={"normal"}>
            <TextField
              error={Boolean(formik.errors.title)}
              label="Title"
              name="title"
              onChange={formik.handleChange}
              value={formik.values.title}
              helperText={formik.errors.title}
            />
          </FormControl>

          <FormControl fullWidth margin={"normal"}>
            <InputLabel>Record type</InputLabel>
            <Select
              required
              value={formik.values.type}
              defaultValue={ETimelineRecordTypes.Note}
              label="type"
              name="type"
              onChange={formik.handleChange}
            >
              <MenuItem value={ETimelineRecordTypes.Note} selected>
                Note
              </MenuItem>
              <MenuItem value={ETimelineRecordTypes.Meal}>Meal</MenuItem>
              <MenuItem value={ETimelineRecordTypes.Mood}>Mood</MenuItem>
              <MenuItem value={ETimelineRecordTypes.Symptom}>Symptom</MenuItem>
            </Select>
          </FormControl>

          {formik.values.type === ETimelineRecordTypes.Meal && (
            <>
              <FormControl fullWidth margin={"normal"}>
                <TextField
                  error={Boolean(formik.errors.proteins)}
                  label="Proteins"
                  name="proteins"
                  type={"number"}
                  onChange={formik.handleChange}
                  value={formik.values.proteins}
                  helperText={formik.errors.proteins}
                />
              </FormControl>

              <FormControl fullWidth margin={"normal"}>
                <TextField
                  error={Boolean(formik.errors.carbs)}
                  label="Carbs"
                  name="carbs"
                  type={"number"}
                  onChange={formik.handleChange}
                  value={formik.values.carbs}
                  helperText={formik.errors.carbs}
                />
              </FormControl>

              <FormControl fullWidth margin={"normal"}>
                <TextField
                  error={Boolean(formik.errors.fats)}
                  label="Fats"
                  name="fats"
                  type={"number"}
                  onChange={formik.handleChange}
                  value={formik.values.fats}
                  helperText={formik.errors.fats}
                />
              </FormControl>
            </>
          )}

          <FormControl fullWidth margin={"normal"}>
            <TextField
              label={"Select images"}
              onChange={handlePicturesChanged}
              InputProps={{
                type: "file",
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <ImageOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <FormControl fullWidth margin={"normal"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                closeOnSelect
                label="Date time"
                value={formik.values.date || dayjs()}
                minDate={dayjs().subtract(1, "year").toDate()}
                maxDate={dayjs().toDate()}
                onChange={(newValue) => {
                  formik.setFieldValue("date", dayjs(newValue).toISOString());
                }}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl
            fullWidth
            margin={"normal"}
            sx={{
              gap: 2,
              flexFlow: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              disabled={!formik.errors || formik.isSubmitting}
            >
              {recordData ? "Update record" : "Create record"}
            </Button>
          </FormControl>
        </form>
      )}

      {formik.status === "success" && (
        <>
          <Typography variant={"subtitle1"} align={"center"} margin={4}>
            Congrats record successfully {recordData ? "updated" : "created"}!
          </Typography>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={handleFormSubmitted}
            size={"large"}
          >
            Ok
          </Button>
        </>
      )}
    </>
  );
};
