import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { BaseLayout } from "./layouts/BaseLayout";
import { DashboardLayout } from "./layouts/DashboardLayout";
import { LoginPage } from "./pages/LoginPage";
import { CgmPage } from "./pages/CgmPage";
import { IgluPage } from "./pages/IgluPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { RegisterPage } from "./pages/RegisterPage";
import { SingleUserPage } from "./pages/SingleUserPage";
import { UsersPage } from "./pages/UsersPage";
import { VitalPage } from "./pages/VitalPage";

export type RouterProps = {
  loggedIn?: boolean;
};

export const AppRouter: React.FC<RouterProps> = ({ loggedIn }) => {
  return useRoutes([
    {
      path: "/dashboard",
      element: loggedIn ? <DashboardLayout /> : <Navigate to={"/login"} />,
      children: [
        {
          path: "users",
          element: <UsersPage />,
        },
        {
          path: "users/:userId",
          element: <SingleUserPage />,
        },
        {
          path: "cgm",
          element: <CgmPage />,
        },
        {
          path: "iglu",
          element: <IgluPage />,
        },
      ],
    },
    {
      path: "/vital/:userId/connect",
      element: <VitalPage />,
    },
    {
      path: "/login",
      element: !loggedIn ? <LoginPage /> : <Navigate to={"/dashboard/users"} />,
    },
    {
      path: "/register",
      element: !loggedIn ? (
        <RegisterPage />
      ) : (
        <Navigate to={"/dashboard/users"} />
      ),
    },
    {
      path: "/",
      element: <BaseLayout />,
      children: [
        {
          path: "/",
          element: <Navigate to={loggedIn ? "/dashboard/users" : "/login"} />,
        },
        // {
        //   path: "vital/:userId/connect",
        //   element: <VitalPage />,
        // },
        { path: "404", element: <NotFoundPage /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
};

export const VitalRouter: React.FC<RouterProps> = () => useRoutes([
  {
    path: "/vital/:userId/connect",
    element: <VitalPage />,
  },
])

export const AuthRouter: React.FC<RouterProps> = ({ loggedIn }) => useRoutes([
  { 
    path: "*", element: <Navigate to="/login" />
  },
  {
    path: "/login",
    element: !loggedIn ? <LoginPage /> : <Navigate to={"/dashboard/users"} />,
  },
  {
    path: "/register",
    element: !loggedIn ? (
      <RegisterPage />
    ) : (
      <Navigate to={"/dashboard/users"} />
    ),
  },
]);