import React, { useEffect, useState } from "react";
import "./App.scss";
import { AppRouter } from "./routes";
import { BrowserRouter } from "react-router-dom";
import { Box } from "@mui/material";
import { authService, userService } from "./services";
import { useDispatch, useSelector } from "react-redux";
import { setIsAuth, setUser } from "./store/user.slice";
import { RootState } from "./store";

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const userState = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    authService.checkAuthState().then(async (userId) => {
      await tryLoadUser(userId);
    });
  }, []);

  const tryLoadUser = async (userId: string) => {
    if (userId) {
      const userData = (await userService.getUser(userId)) as any;
      if (userData?.id) {
        dispatch(setUser(userData));
        dispatch(setIsAuth(true));
      }
    }
    setIsLoaded(true);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <BrowserRouter>
        {/* <VitalRouter /> */}
        {/** @ts-ignore */}
        {isLoaded && <AppRouter loggedIn={userState.isAuth} />}
        {!isLoaded && <>Loading...</>}
      </BrowserRouter>
    </Box>
  );
}

export default App;
