import React, { useEffect, useState } from "react";
import { Card, Typography, Box, Button } from "@mui/material";
import {
  buildMetricBlocks,
  getCorrectValueFormat,
} from "../utils/common.utils";
import { RangeDatePicker } from "./RangeDatePicker";
import { IRangeDate } from "../models/IRangeDate";
import { vitalService } from "../services";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { setPickerData } from "../store/info.slice";
import { ChartsHolder, MetricsHolder } from "./CommonChart";
import { IActivityData } from "../models/IActivityData";
import { SummaryChart } from "./SummaryChart";

export const getActivityDataBlocks = (objectData: any) => {
  return buildMetricBlocks(objectData, [
    "source",
    "user_id",
    "user_key",
    "date",
    "id",
  ]);
};

export type ActivityTabDataProps = {
  activityData: IActivityData[];
};

export const ActivityDataTab = ({ activityData }: ActivityTabDataProps) => {
  const client = useSelector((state: RootState) => state.client.client);
  const [dataProviders, setDataProviders] = useState<string[]>([]);
  const [blocks, setBlocks] = useState<any[]>([]);
  const [activitySummary, setActivitySummary] = useState(activityData);
  const { activityRangePicker } = useSelector((state: RootState) => state.info);
  const dispatch = useDispatch();

  const handleActivityRangePickerChanged = (dates: IRangeDate) => {
    dispatch(setPickerData({ pickerType: "activity", dates }));
  };

  const loadActivityData = async (newDates: IRangeDate) => {
    if (!client?.vitalUserId) return;

    try {
      const data = await vitalService.getActivitySummary(
        client.vitalUserId,
        newDates
      );

      const providersInData = [
        ...new Set(
          data && data.activity
            ? data.activity.map((el: any) => el && el.source.slug)
            : []
        ),
      ];
      setActivitySummary(data.activity);
      //@TODO get average of array not only last
      setBlocks(getActivityDataBlocks(data.activity[0]));
      setDataProviders(providersInData as any);
    } catch (error) {
      setActivitySummary([]);
      setBlocks(getActivityDataBlocks({}));
      setDataProviders([]);
    }
  };

  useEffect(() => {
    loadActivityData(activityRangePicker);
  }, [activityRangePicker]);

  return (
    <>
      <RangeDatePicker
        dates={activityRangePicker}
        onSelected={handleActivityRangePickerChanged}
        title={"Activity"}
      />
      {activitySummary && (
        <>
          <MetricsHolder>
            {blocks.map((el: any, i: number) => (
              <Card
                sx={{
                  padding: 1,
                  flex: "1 1 calc(20% - 16px)",
                }}
                key={i}
              >
                <Typography
                  variant={"subtitle2"}
                  textOverflow={"ellipsis"}
                  noWrap
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 600,
                  }}
                >
                  {el.label}
                </Typography>
                <Typography fontSize={14}>
                  {getCorrectValueFormat(+el.value, el.label)}
                </Typography>
              </Card>
            ))}
          </MetricsHolder>

          <ChartsHolder>
            <Card className={"innerCard"}>
              <Typography
                variant={"subtitle1"}
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                }}
              >
                Activity summary chart
              </Typography>
              <Typography variant={"subtitle2"}>
                {dataProviders.length > 0 && (
                  <>
                    Based on providers:{" "}
                    {dataProviders.map((el: any, i) => (
                      <Box
                        component={"span"}
                        key={i}
                        sx={{
                          border: 1,
                          padding: "0 5px",
                          borderRadius: 2,
                          marginRight: 1,
                          fontSize: 12,
                        }}
                      >
                        {el}
                      </Box>
                    ))}
                  </>
                )}
              </Typography>
              <SummaryChart
                data={activitySummary}
                metrics={blocks}
                dateKey={"date"}
                chartType={"activity"}
              />
            </Card>
          </ChartsHolder>
        </>
      )}
    </>
  );
};
