import dayjs from "dayjs";
import { IRangeDate } from "../models/IRangeDate";
import { ISleepSummary } from "../models/ISleepData";
import { IVitalProvider } from "../models/IVitalProvider";
import { IVitalTypes } from "../models/IVitalTypes";
import { IWorkout } from "../models/IWrokoutData";
import { axiosInstance } from "./axios.service"
import {getDates} from '../utils/common.utils';

const controllerApiName = 'vital';

type dataReqParams = {
  dataType?: 'summary' | 'stream' | 'raw'
  dataCategory?: 'Sleep' | 'Workouts' | 'Profile' | 'Activity' | 'Body' | 'Vitals',
  wokroutId?: string,
  sleepId?: string,
  paginaton?: any,
}

export const getConnectionLink = (userId: string): Promise<any> => {
  return axiosInstance.get(`${controllerApiName}/${userId}/link`);
}

/**
 * SLEEP 
 */
export const getSleepSummary = (userId: string, dates?: IRangeDate): Promise<ISleepSummary> => {
  const {from, to} = getDates(dates?.from as Date, dates?.to as Date);
  return axiosInstance.get(`${controllerApiName}/${userId}/sleep/summary?startDate=${from}${to ? '&endDate='+to : ''}`)
}

export const getSleepStream = (streamId: string, dates?: IRangeDate):Promise<any> => {
  const {from, to} = getDates(dates?.from as Date, dates?.to as Date);
  return axiosInstance.get(`${controllerApiName}/sleep/stream/${streamId}?startDate=${from}${to ? '&endDate='+to : ''}`)
}

export const getSleepRaw = (userId: string, dates?: IRangeDate): Promise<any> => {
  const {from, to} = getDates(dates?.from as Date, dates?.to as Date);
  return axiosInstance.get(`${controllerApiName}/${userId}/sleep/raw?startDate=${from}${to ? '&endDate='+to : ''}`)
}

export const getSleepSumAndStream = (userId: string, dates?: IRangeDate): Promise<any> => {
  return Promise.all([
    getSleepSummary(userId, dates),
    getSleepStream(userId, dates)
  ])
}

/**
 * WORKOUT 
 */
export const getWorkoutsSummary = (userId: string, dates?: IRangeDate): Promise<{workouts: IWorkout[]}> => {
  const {from, to} = getDates(dates?.from as Date, dates?.to as Date);
  return axiosInstance.get(`${controllerApiName}/${userId}/workouts/summary?startDate=${from}${to ? '&endDate='+to : ''}`)
}

export const getWorkoutsStream = (streamId: string, dates?: IRangeDate):Promise<any> => {
  return axiosInstance.get(`${controllerApiName}/workouts/stream/${streamId}`)
}

export const getWorkoutsRaw = (userId: string, dates?: IRangeDate): Promise<any> => {
  return axiosInstance.get(`${controllerApiName}/${userId}/workouts/raw`)
}

/**
 * BODY 
 */
export const getBodySummary = (userId: string, dates?: IRangeDate): Promise<any> => {
  const {from, to} = getDates(dates?.from as Date, dates?.to as Date);
  return axiosInstance.get(`${controllerApiName}/${userId}/body/summary?startDate=${from}${to ? '&endDate='+to : ''}`)
}

export const getBodyRaw = (userId: string): Promise<any> => {
  return axiosInstance.get(`${controllerApiName}/${userId}/body/raw`)
}

/**
 * PROFILE 
 */
export const getProfileSummary = (userId: string): Promise<any> => {
  return axiosInstance.get(`${controllerApiName}/${userId}/profile/summary`)
}

export const getProfileRaw = (userId: string): Promise<any> => {
  return axiosInstance.get(`${controllerApiName}/${userId}/profile/raw`)
}

/**
 * ACTIVITY 
 */
export const getActivitySummary = (userId: string, dates?: IRangeDate): Promise<any> => {
  const {from, to} = getDates(dates?.from as Date, dates?.to as Date);
  return axiosInstance.get(`${controllerApiName}/${userId}/activity/summary?startDate=${from}${to ? '&endDate='+to : ''}`)
}

export const getActivityRaw = (userId: string, dates?: IRangeDate, provider?: string): Promise<any> => {
  const {from, to} = getDates(dates?.from as Date, dates?.to as Date);
  return axiosInstance.get(`${controllerApiName}/${userId}/activity/raw?startDate=${from}${to ? '&endDate='+to : ''}`)
}


/**
 * VITALS
 */
export const getVitalsData = (userId: string, vitalType: IVitalTypes, dates?: IRangeDate, provider?: string): Promise<any> => {
  const {from, to} = getDates(dates?.from as Date, dates?.to as Date);
  if (vitalType === 'glucose') {
    return axiosInstance.get(`${controllerApiName}/${userId}/vitals/glucose`, {
      params: {
        startDate: from,
        endDate: to
      }
    })
  } else {
    return Promise.reject('Not implemented yet');
  }
}



/**
 * PROVIDERS
 */

export const getConnectedProviders = (userId: string): Promise<IVitalProvider[]> => {
  return axiosInstance.get(`${controllerApiName}/${userId}/providers`).then((resp: any) => resp.providers);
}


// - Sleep
// Summary
// Stream
// Raw

// - Workouts
// Summary
// Stream
// Raw

// - Profile
// Summary
// Raw

// - Activity
// Summary
// Raw

// - Body
// Summary
// Raw

// - Vitals
// Blood Oxygen
// Blood Pressure
// Cholesterol
// Glucose
// Heartrate
// IGE
// IGG
