import {
  Box,
  Button,
  Card,
  IconButton,
  ImageList,
  ImageListItem,
  LinearProgress,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { CreateTimelineRecordForm } from "./CreateTimelineRecord";
import dayjs from "dayjs";
import { ITimelineRecord } from "../models/ITimelineRecord";
import { ETimelineRecordTypes } from "../models/ETimelineRecordTypes";
import styled from "@emotion/styled";
import { EditOutlined, RemoveCircleOutline } from "@mui/icons-material";
import { IFormResult } from "../models/IFormResult";
import { timelineService } from "../services";
import { MainIconButton } from "./MainIconButton";
import { BasicModal } from "./BasicModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  setCurrentRecordCategory,
  setPickerData,
  setTimelineRecords,
  setTimelineRecordsLoaded,
} from "../store/info.slice";
import { RangeDatePicker } from "./RangeDatePicker";
import { IRangeDate } from "../models/IRangeDate";

export type TimelineListProps = {};

const isDevENV = (): boolean => process.env.REACT_APP_NODE_ENV !== "production";

export const RecordTypeChip: React.FC<{ recType: ETimelineRecordTypes }> = ({
  recType,
}) => {
  let label = "";
  switch (recType) {
    case ETimelineRecordTypes.Meal:
      label = "🍽 Meal";
      break;
    case ETimelineRecordTypes.Mood:
      label = "🙂 Mood";
      break;
    case ETimelineRecordTypes.Note:
      label = "🗒 Note";
      break;
    case ETimelineRecordTypes.Symptom:
      label = "🩺 Symptom";
      break;
  }

  return (
    <Typography variant={"caption"} fontWeight={500}>
      {label}
    </Typography>
  );
};

const ImageHolder = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  maxWidth: 120,
  "& > img": { width: "100%" },
}));

const HiddenActions = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  left: 0,
  bottom: 0,
  transform: "translateY(100%)",
  transition: "all .3s ease",
  boxShadow: "0 0 0 0 rgba(0,0,0,.1)",
  zIndex: 10,
  background: "#fff",
  padding: "4px 8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  fontSize: 12,
  button: {
    width: 24,
    height: 24,
    svg: {
      width: "100%",
    },
  },
}));

const RecordHolder = styled(Card)(({ theme }) => ({
  position: "relative",
  marginBottom: 16,
  padding: 8,
  "&:hover": {
    ".ActionsHolder": {
      transform: "translateY(0)",
      boxShadow: "0 -2px 10px 0 rgba(0,0,0,.1)",
    },
  },
}));

export const TimelineList: React.FC<TimelineListProps> = ({}) => {
  const {
    info: {
      timelineRangePicker,
      recordCategories,
      timelineRecords,
      timelineRecordsLoaded,
      sleepRangePicker,
    },
    client,
  } = useSelector((state: RootState) => state);

  const [filteredTimelineList, setFilteredTimelineList] =
    useState<ITimelineRecord[]>(timelineRecords);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentRecordId, setCurrentRecordId] = useState<number | null>(null);
  const [editingRecord, setEditingRecord] = useState<ITimelineRecord | null>(
    null
  );

  const dispatch = useDispatch();
  const theme = useTheme();

  const handleAddNewEvent = () => {
    setShowCreateModal(true);
  };

  const handleCreateRecordFormIsSubmitted = (data?: IFormResult & any) => {
    setShowCreateModal(!data?.success);
    if (data.recordData) {
      dispatch(setTimelineRecords([...filteredTimelineList, data.recordData]));
    }
  };

  const handleUpdateRecordFormIsSubmitted = (data?: IFormResult & any) => {
    setEditMode(!data?.success);
    if (data.recordData && editingRecord) {
      const updatedRecords = filteredTimelineList.map((el) => {
        if (el.id === editingRecord.id) {
          el = { ...el, ...data.recordData };
        }
        return el;
      });
      setFilteredTimelineList([...updatedRecords]);
    }
  };

  const handleDeleteRecord = async (recordId: number) => {
    setDeleteMode(true);
    setCurrentRecordId(recordId);
  };

  const handleConfirmDelete = async (confirmed: boolean) => {
    if (confirmed && currentRecordId) {
      return timelineService
        .deleteRecord(currentRecordId as number)
        .then((resp) => {
          if (resp.statusCode) {
            return alert("Something went wrong. Please try again later.");
          }
          setFilteredTimelineList([
            ...filteredTimelineList.filter((el) => el && el.id !== resp[0].id),
          ]);
          setCurrentRecordId(null);
          setDeleteMode(false);
        })
        .catch(console.error);
    }
    setDeleteMode(false);
    setCurrentRecordId(null);
  };

  const handleEditRecord = async (recordId: number) => {
    await setEditingRecord(
      timelineRecords.filter((el) => el.id === recordId)[0] || null
    );
    await setEditMode(true);
  };

  const filterRecords = (recType: ETimelineRecordTypes | null) => {
    if (!timelineRecords.length) return;

    dispatch(setCurrentRecordCategory(recType));
    if (!recType) {
      return setFilteredTimelineList(timelineRecords);
    }

    const filteredList = timelineRecords.filter(
      (el) => el && el.type === recType
    );
    setFilteredTimelineList(filteredList);
  };

  const handleTimelineDatesChanged = (dates: IRangeDate) => {
    dispatch(
      setPickerData({
        pickerType: "timeline",
        dates,
      })
    );
  };

  useEffect(() => {
    if (!client || !client.client?.id) {
      return;
    }
    _loadTimelineRecords(client.client.id, timelineRangePicker);
  }, [timelineRangePicker]);

  // useEffect(() => {
  //   if (!client || !client.client?.id) {
  //     return;
  //   }
  //   _loadTimelineRecords(sleepRangePicker);
  // }, [sleepRangePicker]);

  // useEffect(() => {
  //   console.log('====================================');
  //   console.log((2));
  //   console.log('====================================');
  //   if (!client || !client.client?.id) {
  //     return;
  //   }
  //   _loadTimelineRecords(client.client.id, timelineRangePicker);
  // }, [])

  const _loadTimelineRecords = (clientId: number, dates: IRangeDate) => {
    timelineService
      // @ts-ignore
      .getUserTimelineData(clientId, dates)
      .then((resp) => {
        if (resp) {
          dispatch(setTimelineRecords(resp));
          dispatch(setCurrentRecordCategory(null));
          setFilteredTimelineList(resp);
        }
      })
      .finally(() => dispatch(setTimelineRecordsLoaded(true)));
  };

  console.log("filteredTimelineList", filteredTimelineList)  //DEBUG

  return (
    <>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant={"subtitle2"}>Timeline</Typography>
        <MainIconButton
          title={"Add record"}
          onClick={handleAddNewEvent}
          icon={"add_circle_outlined"}
        />
      </Stack>

      <Stack flexDirection={"column"}>
        <Box>
          <RangeDatePicker
            hideTitle
            small
            dates={timelineRangePicker}
            onSelected={handleTimelineDatesChanged}
          />
        </Box>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {recordCategories.map((el, i) => (
            <Box
              key={i}
              sx={{
                fontSize: 14,
                textTransform: "lowercase",
                textDecoration: el.active ? "underline" : "none",
                cursor: "pointer",
                color: theme.palette.primary.main,
                opacity: el.active ? 1 : 0.6,
              }}
              component={"span"}
              onClick={() => filterRecords(el.type)}
            >
              {el.label}
            </Box>
          ))}
        </Stack>
      </Stack>

      <Box>
        {filteredTimelineList.map((el, i) => (
          <RecordHolder key={el.id}>
            <Box>
              <Stack
                alignItems={"center"}
                justifyContent={"space-between"}
                flexDirection={"row"}
              >
                <RecordTypeChip recType={el.type} />
                {el.date && (
                  <Typography variant={"caption"}>
                    {"📆" + dayjs(el.date).format("DD MMM HH:mm A")}
                  </Typography>
                )}
              </Stack>
              {el.title && (
                <Typography variant={"subtitle1"}>{el.title}</Typography>
              )}
              <Typography variant={"body2"}>{el.message}</Typography>
              {el.type === ETimelineRecordTypes.Meal && (
                <>
                  <Typography variant={"body2"}>
                    {el.proteins && `Proteins: ${el.proteins}`}
                  </Typography>
                  <Typography variant={"body2"}>
                    {el.carbs && `Carbs: ${el.carbs}`}
                  </Typography>
                  <Typography variant={"body2"}>
                    {el.fats && `Fats: ${el.fats}`}
                  </Typography>
                </>
              )}

              {el.pictures && el.pictures.length > 0 && (
                <ImageList
                  sx={{ width: "100%", height: "100%", marginBottom: 0 }}
                >
                  {el.pictures.map((imagePath: any) => (
                    <ImageListItem key={imagePath}>
                      <img
                        src={
                          //isDevENV()
                          //  ? `https://platform.biohackinglab.ru/${imagePath}`
                          //  : `/${imagePath}`
                          imagePath.replace("./", "/")
                        }
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              )}
            </Box>
            <HiddenActions className={"ActionsHolder"}>
              <Tooltip title="Edit record" placement="top">
                <IconButton
                  size={"small"}
                  onClick={() => handleEditRecord(el.id as number)}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete record" placement="top">
                <IconButton
                  color={"error"}
                  size={"small"}
                  onClick={() => handleDeleteRecord(el.id as number)}
                >
                  <RemoveCircleOutline />
                </IconButton>
              </Tooltip>
            </HiddenActions>
          </RecordHolder>
        ))}

        {timelineRecordsLoaded && filteredTimelineList?.length === 0 && (
          <Typography>no records found</Typography>
        )}
        {!timelineRecordsLoaded && filteredTimelineList?.length === 0 && (
          <LinearProgress variant={"indeterminate"} />
        )}
      </Box>

      <BasicModal
        open={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        title={"Create new record"}
      >
        <CreateTimelineRecordForm
          formSubmitted={handleCreateRecordFormIsSubmitted}
        />
      </BasicModal>

      <BasicModal
        open={deleteMode}
        onClose={() => setDeleteMode(false)}
        title={"are you sure?"}
      >
        <Typography marginTop={4} marginBottom={4}>
          {"You are about to delete a record. Do you confirm?"}
        </Typography>
        <Stack direction={"row"} gap={2}>
          <Button
            fullWidth
            variant={"text"}
            color={"info"}
            onClick={() => handleConfirmDelete(false)}
            size={"large"}
          >
            Cancel
          </Button>
          <Button
            fullWidth
            variant={"contained"}
            color={"info"}
            onClick={() => handleConfirmDelete(true)}
            size={"large"}
          >
            Acknowlage
          </Button>
        </Stack>
      </BasicModal>

      <BasicModal
        open={editMode}
        onClose={() => setEditMode(false)}
        title={"Edit record"}
      >
        <CreateTimelineRecordForm
          formSubmitted={handleUpdateRecordFormIsSubmitted}
          recordData={editingRecord}
        />
      </BasicModal>
    </>
  );
};
