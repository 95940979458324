import { Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";
import React from "react";
import { Outlet } from "react-router-dom";
import { BaseInnerPage } from "../components/BaseInnerPage";

export const BaseLayout = () => {
  return (
    <BaseInnerPage title="Base page">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Base layout
          </Typography>
        </Stack>

        <Outlet/>
      </Container>
    </BaseInnerPage>
  );
};
