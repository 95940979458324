import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { ReactNode } from "react";

type BasicModalProps = {
  open: boolean;
  children: ReactNode;
  onClose: () => void;
  title?: string;
};

export const BasicModal: React.FC<BasicModalProps> = ({ open, onClose, children, title }) => {
  return (
    <Modal
      open={Boolean(open)}
      onClose={onClose}
      sx={{ display: "flex" }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: 100,
          display: "flex",
          flexFlow: "column nowrap",
          margin: "auto",
          width: 400,
          alignSelf: "center",
          justifySelf: "center",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        {title && <Typography variant="h6">{title}</Typography>}
        {children}
      </Box>
    </Modal>
  );
};
