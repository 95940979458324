import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CircularProgress,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useVitalLink } from "@tryvital/vital-link";
import { useNavigate, useParams } from "react-router-dom";
import { BaseInnerPage } from "../components/BaseInnerPage";
import { vitalService } from "../services";

export const VitalPage = () => {
  const params = useParams();
  const [connectLink, setConnectLink] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [connected, setIsConnected] = useState(false);
  const nav = useNavigate();

  const onSuccess = useCallback((metadata: any) => {
    // Device is now connected.
    console.log("onSuccess", metadata);
    // nav("/");
    setIsConnected(true);
    setIsLoading(false);
  }, []);

  const onExit = useCallback((metadata: any) => {
    // User has quit the link flow.
    console.log("onExit", metadata);
    getLinkToken(params.userId as string);
    setIsLoading(false);
  }, []);

  const onError = useCallback((metadata: any) => {
    // Error encountered in connecting device.
    console.log("onError", metadata);
    setIsLoading(false);
  }, []);

  const config = {
    onSuccess,
    onExit,
    onError,
    env: "sandbox",
    region: "eu",  //TODO take from .env
  };

  const { ready, open } = useVitalLink(config);

  useEffect(() => {
    if (params.userId) {
      getLinkToken(params.userId);
    }
    // window.open(window.location.href, '_self')
  }, []);

  const getLinkToken = (userId: string) => {
    setIsLoading(true);
    vitalService.getConnectionLink(userId).then((resp) => {
      console.log("resp", resp);  //DEBUG
      //setConnectLink(resp);
      setConnectLink(`https://link.tryvital.io/?token=${resp}&env=sandbox&region=eu`)  //TODO take from .env
      setIsLoading(false);
    });
  };

  const theme = useTheme();

  return (
    <BaseInnerPage title="Vital">
      <Container
        sx={{
          width: "100vw",
          height: "100vh",
        }}
      >
        <Box
          padding={2}
          display={"flex"}
          height={"100vh"}
          justifyContent={"center"}
          justifyItems={"center"}
          alignContent={"center"}
          alignItems={"center"}
        >
          {!connected && connectLink && !isLoading && (
            <Button onClick={() => open(connectLink)} variant={"outlined"}>
              Connect
            </Button>
          )}
          {connected && !isLoading && (
            <Card
              sx={{
                padding: 2,
              }}
            >
              <Typography variant={"h4"} color={theme.palette.success.main}>
                Success
              </Typography>
              <Typography>You successfully added new device!</Typography>
              <Button
                onClick={() => {
                  close();
                  nav("/");
                }}
              >
                Ok
              </Button>
            </Card>
          )}
          {(!connectLink || isLoading) && <CircularProgress />}
        </Box>
      </Container>
    </BaseInnerPage>
  );
};
