import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Stack,
  Tab,
  Tabs,
  Box,
  Typography,
  Snackbar,
  Alert,
  Chip,
  Skeleton,
  Collapse,
  IconButton,
} from "@mui/material";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { BaseInnerPage } from "../components/BaseInnerPage";
import { timelineService, userService, vitalService } from "../services";
import TodayIcon from '@mui/icons-material/Today';
import BedtimeIcon from "@mui/icons-material/Bedtime";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { TabPanel } from "../components/TabPanel";
import { DailyDataTab } from "../components/DailyDataTab";
import { SleepDataTab } from "../components/SleepDataTab";
import { WorkoutDataTab } from "../components/WorkoutDataTab";
import { IWorkout } from "../models/IWrokoutData";
import { ISleepData } from "../models/ISleepData";
import { IVitalProvider } from "../models/IVitalProvider";
import { useDispatch, useSelector } from "react-redux";
import { setClient } from "../store/client.slice";
import copy from "copy-to-clipboard";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import dayjs from "dayjs";
import { TimelineList } from "../components/TimelineList";
import { Bloodtype, SportsGymnastics } from "@mui/icons-material";
import { EditUserForm } from "../components/EditUserForm";
import { RootState } from "../store";
import { IFormResult } from "../models/IFormResult";
import { ActivityDataTab } from "../components/ActivityDataTab";
import { IActivityData } from "../models/IActivityData";
import { MainIconButton } from "../components/MainIconButton";
import { BasicModal } from "../components/BasicModal";
import { CgmDataTab } from "../components/CgmDataTab";
import { ICgmData } from "../models/ICgmData";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import {
  setTimelineRecords,
  setTimelineRecordsLoaded,
} from "../store/info.slice";
import { IBodyData } from "../models/IBodyData";

import queryString from "query-string";

const pageTabs = [
  {
    title: "Daily",
    icon: <TodayIcon />,
    id: 0,
  },
  {
    title: "Sleep",
    icon: <BedtimeIcon />,
    id: 1,
  },
  {
    title: "Workouts",
    icon: <FitnessCenterIcon />,
    id: 2,
  },
  {
    title: "Activity",
    icon: <SportsGymnastics />,
    id: 3,
  },
  {
    title: "Cgm",
    icon: <Bloodtype />,
    id: 4,
  },
];

export const SingleUserPage = () => {
  const { t, i18n } = useTranslation();
  const [curTab, setCurTab] = React.useState(0);
  const [updateSucceeded, setUpdateSucceeded] = useState<boolean | null>(null);
  const [sleepData, setSleepData] = useState<ISleepData[]>([]);
  const [workoutData, setWorkoutData] = useState<IWorkout[]>([]);
  const [activeProviders, setActiveProviders] = useState<IVitalProvider[]>([]);
  const [activityData, setActivityData] = useState<IActivityData[]>([]);
  const [bodyData, setBodyData] = useState<IBodyData>();
  const [editUserMode, setEditUserMode] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [cgmData, setCgmData] = useState<ICgmData[]>([]);
  const [userExist, setUserExist] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [noVitalDataAlert, setNoVitalDataAlert] = useState(false);
  const { result } = useSelector(
    (state: RootState) => state.info.confirmationModal
  );
  const { client } = useSelector((state: RootState) => state.client);
  const { timelineRecordsLoaded } = useSelector(
    (state: RootState) => state.info
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurTab(newValue);
  };

  const dispatch = useDispatch();
  const params = useParams();
  const { search } = useLocation();
  const nav = useNavigate();

  useEffect(() => {
    if (!params.userId) {
      return nav(-1);
    }

    const { editMode } = queryString.parse(location.search, {
      parseBooleans: true,
    });
    loadUserData(params.userId, editMode as boolean);
    return () => {
      dispatch(setClient(null));
      dispatch(setTimelineRecords([]));
    };
  }, []);

  const loadUserData = async (userId: string, editMode: boolean) => {
    const userData = await userService.getUser(userId)
    .then(resp => {
      if (resp) {
        dispatch(setClient(resp));
      }
      return resp;
    });

    if (!userData) {
      return setUserExist(false);
    }
    
    // await timelineService
    //   .getUserTimelineData(userId)
    //   .then((resp) => {
    //     if (resp && resp.length > 0) {
    //       dispatch(setTimelineRecords(resp));
    //     }
    //   })
    //   .finally(() => {
    //     dispatch(setTimelineRecordsLoaded(true))
    //   });

    if (userData.vitalUserId) {
      // I know ok? It need to be refactored
      const rqs = await Promise.all([
        vitalService.getSleepSummary(userData.vitalUserId),
        vitalService.getWorkoutsSummary(userData.vitalUserId),
        vitalService.getConnectedProviders(userData.vitalUserId),
        vitalService.getActivitySummary(userData.vitalUserId),
        vitalService.getBodySummary(userData.vitalUserId),
        vitalService.getVitalsData(userData.vitalUserId, "glucose"),
      ]).finally(() => dispatch(setTimelineRecordsLoaded(true)));
      const sleepSummary = rqs[0];
      const workoutSumary = rqs[1];
      const connectedProvidersData = rqs[2];
      const activitySummary = rqs[3];
      const bodySummery = rqs[4];
      const cgmSummary = rqs[5];

      setSleepData(sleepSummary.sleep);
      setWorkoutData(workoutSumary.workouts);
      setActiveProviders(connectedProvidersData);
      setActivityData(activitySummary.activity);
      setCgmData(cgmSummary);
      setBodyData(bodySummery?.body[0] || {}); 
    } else {
      setNoVitalDataAlert(true)
    }

    setEditUserMode(editMode)
  };

  const handleUserEditClicked = () => {
    setEditUserMode(true);
  };

  const handleEditUserFormSubmitted = (data?: IFormResult) => {
    if (!data) {
      return;
    }
    if (data.success) {
      setUpdateSucceeded(null);
      setEditUserMode(false);
      return;
    }

    userService.updateUserData(params?.userId as string, data).then((resp) => {
      if (resp?.statusCode === 400) {
        return setUpdateSucceeded(false);
      }
      dispatch(setClient(resp));
      return setUpdateSucceeded(true);
    });
  };

  const handleGetProviderConnectionLinkClick = () => {
    const link = `${window.location.origin}/vital/${client?.vitalUserId}/connect`;
    copy(link);
    setLinkCopied(true);
  };

  const handleEditModalClose = () => {
    if (search) {
      setSearchParams("", { replace: true });
    }
    setEditUserMode(false);
  };

  const hasDailyTab = (params?.userId == "2");

  return (
    <BaseInnerPage title="User">
      <Stack
        direction="row"
        alignItems={"center"}
        alignContent={"center"}
        justifyContent="space-between"
        mb={1}
      >
        <Button size={"medium"} onClick={() => nav(-1)}>
          <ArrowBackIosNewRoundedIcon fontSize={"small"} />
          Back
        </Button>

        <Stack
          direction="row"
          minWidth={"25%"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h4">{client?.firstName} {client?.lastName}</Typography>
          <MainIconButton
            onClick={handleUserEditClicked}
            disabled={!client}
            icon={"edit"}
            title={"Edit user"}
          />
        </Stack>
      </Stack>

      <Collapse in={!userExist}>
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                nav(-1);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          User doesn't exist!
        </Alert>
      </Collapse>
  
      <Grid container>
        <Grid item xs={9} paddingRight={2}>
          <Typography variant={"h6"}>{t('user.body_and_activity_data')}</Typography>
          <Box>
            <Typography>
              {t('user.weight')}: {bodyData?.weight || client?.weight} {t('user.weight_uom')}
            </Typography>
            <Typography>
              {t('user.height')}: {bodyData?.height || client?.height} {t('user.height_uom')}
            </Typography>
            <Typography>
              {t('user.daily_steps')}: {activityData ? activityData[0]?.steps || 'N/A' : "N/A"}
            </Typography>
          </Box>
          <br />
          {/* <Box>
            <SleepDataTab sleepData={sleepData} />
          </Box>
          <Box>
            <WorkoutDataTab workoutData={workoutData} />
          </Box>
          <Box>
            <ActivityDataTab activityData={activityData} />
          </Box>
          <Box>
            <CgmDataTab cgmData={cgmData} />
          </Box> */}

          <Box>
            <Typography variant={"h6"}>Data by categories</Typography>
            <Tabs
              value={curTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {pageTabs.slice(hasDailyTab ? -5 : -4).map((el) => (
                <Tab
                  sx={{
                    color: "rgba(25,33,119,.6)",
                  }}
                  label={el.title}
                  icon={el.icon}
                  iconPosition={"start"}
                  key={Math.random()}
                />
              ))}
            </Tabs>

            { hasDailyTab && (
              <TabPanel value={curTab} index={0}>
                <DailyDataTab dailyData={sleepData} />
              </TabPanel>)
            }
            <TabPanel value={curTab} index={hasDailyTab ? 1 : 0}>
              <SleepDataTab sleepData={sleepData} />
            </TabPanel>
            <TabPanel value={curTab} index={hasDailyTab ? 2 : 1}>
              <WorkoutDataTab workoutData={workoutData} />
            </TabPanel>
            <TabPanel value={curTab} index={hasDailyTab ? 3 : 2}>
              <ActivityDataTab activityData={activityData} />
            </TabPanel>
            <TabPanel value={curTab} index={hasDailyTab ? 4 : 3}>
              <CgmDataTab cgmData={cgmData} />
            </TabPanel>
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Stack gap={2}>
            <Box>
              {timelineRecordsLoaded && (
                <>
                  {client?.birthday && (
                    <Typography>
                      {t('user.age')}: {dayjs().diff(dayjs(client.birthday), "years")}
                    </Typography>
                  )}
                  <Typography>{t('user.note')}: {client?.userNote}</Typography>
                  <Box marginBottom={1}>
                    {t('user.providers')}:{" "}
                    {activeProviders &&
                      activeProviders.map((el) => (
                        <Chip
                          size={"small"}
                          key={el.slug}
                          label={el.name}
                          sx={{ marginRight: 1 }}
                          icon={
                            <img
                              style={{ width: "14px", height: "14px" }}
                              src={el.logo}
                            />
                          }
                        />
                      ))}
                  </Box>
                  <MainIconButton
                    onClick={handleGetProviderConnectionLinkClick}
                    icon={"link"}
                    title={t('provider.generate_connect_link')}
                  />
                </>
              )}
              {!timelineRecordsLoaded && (
                <Box>
                  {[0, 1, 2].map((el, i) => {
                    return (
                      <Skeleton
                        variant={"text"}
                        key={i}
                        sx={{
                          padding: 0,
                          height: "18px",
                          margin: "0 0 10px 0",
                          transform: "scale(1)",
                        }}
                      />
                    );
                  })}
                  <Skeleton variant={"rectangular"} height={30} />
                </Box>
              )}
            </Box>

            {client && <TimelineList />}
          </Stack>
        </Grid>
      </Grid>

      <BasicModal
        open={editUserMode}
        onClose={handleEditModalClose}
        title={t('user.actions.edit')}
      >
        <EditUserForm
          userData={client}
          updateSuccess={updateSucceeded}
          formSubmitted={handleEditUserFormSubmitted}
        />
      </BasicModal>

      <Snackbar
        open={linkCopied}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        autoHideDuration={2000}
        onClose={() => setLinkCopied(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Link copied
        </Alert>
      </Snackbar>

      <Snackbar
        open={noVitalDataAlert}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        autoHideDuration={2000}
        onClose={() => setNoVitalDataAlert(false)}
      >
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setNoVitalDataAlert(false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Can not get Vital data, user is not connected to Vital!
        </Alert>
      </Snackbar>
    </BaseInnerPage>
  );
};
