import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ERoles } from "../models/ERoles";
import { getChangedValues } from "../utils/common.utils";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { IFormResult } from "../models/IFormResult";
import { userService } from "../services";
import { useNavigate } from "react-router-dom";
import { BirthdayPicker } from "./BirthdayPicker";
import { userValidationSchema } from "../utils/validation.utils";

type EditUserFormProps = {
  userData: any;
  updateSuccess: boolean | null;
  formSubmitted: (data?: IFormResult) => void;
};

export const EditUserForm: React.FC<EditUserFormProps> = ({
  userData,
  updateSuccess,
  formSubmitted,
}) => {
  const nav = useNavigate();

  const formik = useFormik({
    initialValues: userData || {},
    validationSchema: userValidationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      const newValues = getChangedValues(values, userData);
      if (Object.keys(newValues).length === 0) {
        formik.setSubmitting(false);
        formSubmitted();
        return;
      }
      formSubmitted(newValues);
      // formik.setStatus('success');
      // formik.resetForm();
      formik.setSubmitting(false);
    },
  });

  const isAdmin = useSelector(
    (state: RootState) => state.user.user?.role === "ADMIN"
  );

  const handleDeleteUser = () => {
    userService.deleteUser(userData.id).then((resp) => {
      if (resp.status) {
        nav(-1);
      }
    });
  };

  useEffect(() => {
    if (updateSuccess === null) {
      return formik.resetForm();
    }
    return formik.setStatus(updateSuccess ? "success" : "error");
  }, [updateSuccess]);

  return (
    <>
      {formik.status !== "success" && formik.status !== 'error' && (
        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth margin={"normal"}>
            <TextField
              required
              error={Boolean(formik.errors.phoneNumber)}
              label="Phone number"
              name="phoneNumber"
              onChange={formik.handleChange}
              value={formik.values.phoneNumber || ""}
            />
          </FormControl>

          {isAdmin && (
            <FormControl fullWidth margin={"normal"}>
              <InputLabel>Role</InputLabel>
              <Select
                value={formik.values.role || ERoles.USER}
                defaultValue={ERoles.USER}
                label="Role"
                name="role"
                onChange={formik.handleChange}
              >
                <MenuItem value={ERoles.USER} selected>
                  User
                </MenuItem>
                <MenuItem value={ERoles.COACH}>Coach</MenuItem>
                <MenuItem value={ERoles.ADMIN}>Admin</MenuItem>
              </Select>
            </FormControl>
          )}

          <FormControl fullWidth margin={"normal"}>
            <TextField
              error={Boolean(formik.errors.firstName)}
              label="First Name"
              name="firstName"
              onChange={formik.handleChange}
              value={formik.values.firstName || ""}
            />
          </FormControl>

          <FormControl fullWidth margin={"normal"}>
            <TextField
              error={Boolean(formik.errors.lastName)}
              label="Last Name"
              name="lastName"
              onChange={formik.handleChange}
              value={formik.values.lastName || ""}
            />
          </FormControl>

          <FormControl fullWidth margin={"normal"}>
            <BirthdayPicker formik={formik} />
          </FormControl>

          <FormControl fullWidth margin={"normal"}>
            <TextField
              error={Boolean(formik.errors.weight)}
              label="Weight"
              name="weight"
              onChange={formik.handleChange}
              value={formik.values.weight || null}
            />
          </FormControl>

          <FormControl fullWidth margin={"normal"}>
            <TextField
              error={Boolean(formik.errors.height)}
              label="Height"
              name="height"
              onChange={formik.handleChange}
              value={formik.values.height || null}
            />
          </FormControl>

          <FormControl fullWidth margin={"normal"}>
            <TextField
              label="User note"
              name="userNote"
              onChange={formik.handleChange}
              value={formik.values.userNote || ""}
            />
          </FormControl>

          <FormControl
            fullWidth
            margin={"normal"}
            sx={{
              gap: 2,
              flexFlow: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              color={"error"}
              onClick={handleDeleteUser}
              disabled={formik.isSubmitting}
            >
              Delete user
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Save changes
            </Button>
          </FormControl>
        </form>
      )}

      {formik.status === "success" && (
        <>
          <Typography variant={"subtitle1"} align={"center"} margin={4}>
            Congrats user data successfully updatet!
          </Typography>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => formSubmitted({ success: true })}
            size={"large"}
          >
            Ok
          </Button>
        </>
      )}

      {formik.status === "error" && (
        <>
          <Typography variant={"subtitle1"} align={"center"} margin={4}>
            Something went wrong!
            <br/>
            During user update caused error, please try again later!
          </Typography>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => formSubmitted({ success: true })}
            size={"large"}
          >
            Ok
          </Button>
        </>
      )}
    </>
  );
};
