import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BaseInnerPage } from "../components/BaseInnerPage";
import { BasicModal } from "../components/BasicModal";
import { CreateUserForm } from "../components/CreateUserForm";
import { UsersTable } from "../components/UsersTable";
import { IFormResult } from "../models/IFormResult";
import { IUser } from "../models/IUser";
import { userService } from "../services"
import { setCoachesList, setClientsList } from "../store/user.slice";

export const UsersPage = () => {
  const [usersList, setUsersList] = useState<IUser[]>([]);
  const [createUserMode, setCreateUserMode] = useState(false);
  const dispatch = useDispatch();

  const handlePageChanged = (pageNum: number) => {
    console.log(pageNum);
  };

  useEffect(() => {
    if (!usersList.length) {
      userService.getAllUsers().then(async (resp) => {
        const coaches = resp.filter((el => el && el.role === 'COACH'));
        const clients = resp.filter((el => el && el.role === 'USER'));
        dispatch(setCoachesList(coaches));
        dispatch(setClientsList(clients));
        await setUsersList(resp);
      });
    }
  }, []);

  const handleCreateUserFormSubmitted = (result: IFormResult) => {
    if (result.success) {
      setCreateUserMode(false);
      window.location.reload();
    }
  };

  return (
    <>
      <BaseInnerPage title="User">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <Button variant="contained" onClick={() => setCreateUserMode(true)}>
            Add User
          </Button>
        </Stack>

        <UsersTable usersList={usersList} pageChange={handlePageChanged} />

        <BasicModal
          open={createUserMode}
          onClose={() => setCreateUserMode(false)}
          title={"Create new user"}
        >
          <CreateUserForm formSubmitted={handleCreateUserFormSubmitted} />
        </BasicModal>
      </BaseInnerPage>
    </>
  );
};
