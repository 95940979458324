import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  Typography,
} from "@mui/material";
import React from "react";
import { DrawerHeader } from "./MainDrawerHeader";
import {
  ChevronLeft,
  ChevronRight,
  Inbox,
  Group,
  Dashboard,
} from "@mui/icons-material";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { DRAWER_WIDTH } from "../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { blue } from "@mui/material/colors";

export type AppSideBarProps = {
  open: boolean;
  handleOpen: () => void;
};

const dashboardRoutes = [
  {
    path: "users",
    title: "Users",
    icon: <Group />,
  },
  {
    path: "https://platform.biohackinglab.ru/web2",
    title: "Web-2",
    icon: <QueryStatsIcon />,
  },
  {
    path: "iglu",
    title: "CGM Analysis",
    icon: <Dashboard />,
  },
];

export const AppSideBar: React.FC<AppSideBarProps> = ({ open, handleOpen }) => {
  const theme = useTheme();
  const nav = useNavigate();
  const location = useLocation();
  const isCurrentUrl = (path: string) =>
    location.pathname === "/dashboard/" + path;

  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
          background: '#fff',
        },
      }}
      variant={'persistent'}
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={handleOpen}>
          {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {dashboardRoutes.map(({ title, icon, path }, index) => (
          <ListItem key={title} disablePadding selected={isCurrentUrl(path)}>
            <ListItemButton onClick={ () => {
                if (path.startsWith("http")) {
                  window.open(path, '_self')
                } else {
                  nav(path)
                }
              } }>
              <ListItemIcon
                sx={{ color: isCurrentUrl(path) ? blue[400] : null }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Typography variant={"body2"}>
        built on 2023-11-10 at 14:43
      </Typography>
    </Drawer>
  );
};
