import axios from "axios";
import { IUser } from "../models/IUser";
import { axiosInstance } from "./axios.service";

const CONTROLLER = "users";

export const getAllUsers = (): Promise<IUser[]> => {
  return axiosInstance.get(`${CONTROLLER}`);
};

export const getUser = (userId: number | string): Promise<IUser> => {
  return axiosInstance.get(`${CONTROLLER}/${userId}`)
  .then((resp: any) => {
    if (resp.message) {
      return null
    }
    return resp;
  })
  .catch(err => null);
};

export const updateUserData = (
  userId: number | string,
  userData: any
): Promise<any> => {
  const bodyFormData = new FormData();
  for (const p in userData) {
    if (p && userData.hasOwnProperty(p)) {
      bodyFormData.append(p, userData[p]);
    }
  }

  return axiosInstance.put(`${CONTROLLER}/${userId}`, bodyFormData);
};

export const deleteUser = (userId: number | string): Promise<any> => {
  return axiosInstance.delete(`${CONTROLLER}/${userId}`);
};

export const updateAssignments = (
  userId: number,
  idsList: number[],
  assignmentType: "clientIds" | "coachIds"
): Promise<IUser> => {
  const endpoint = assignmentType === "clientIds" ? "clients" : "coaches";
  const dataObj = JSON.stringify({ [assignmentType]: idsList });

  return axiosInstance.post(`${CONTROLLER}/${userId}/assign-${endpoint}`, dataObj, {
    headers: {
      "Content-type": "application/json",
    },
  });
};
