import dayjs from 'dayjs';
import { axiosInstance } from './axios.service';


export const logIn = (username: string, password: string): Promise<any> => {
  const formData = new FormData();
  formData.append('phoneNumber', username);
  formData.append('password', password)
  return axiosInstance.post(`/auth/login`, formData);
}

export const checkAuthState = (): Promise<string> => {
  const token = localStorage.getItem("access_token") || '';
  const tokenExpirationTime = localStorage.getItem("access_token_expires_at") || 0;
  const userId = localStorage.getItem("userId") || '-1';

  if (tokenExpirationTime && dayjs().isAfter(+tokenExpirationTime) || !token || !userId) {
    localStorage.clear();
    return Promise.resolve('')
  }

  return Promise.resolve(userId)
}

export const registerUser = (userData: any): Promise<any> => {
  const formData = new FormData();
  for (const p in userData) {
    if (p && userData.hasOwnProperty(p)) {
      formData.append(p, userData[p]);
    }
  }
  return axiosInstance.post(`auth/register`, formData)
}