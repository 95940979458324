import React from 'react'


export const CgmPage = () => {

  return (
    <>
      <iframe
        src={ `https://platform.biohackinglab.ru/web2?token=${ localStorage.getItem("access_token") }` }
        width="100%" height="720px"
        frameBorder="0"></iframe>
    </>
  )

}
