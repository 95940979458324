import React from "react";
import {
  Button,
  IconButton,
  styled,
  Toolbar,
  useTheme,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import { DRAWER_WIDTH } from "../constants";
import { useDispatch} from "react-redux";
import { Box } from "@mui/system";

export type AppTopBarProps = {
  open: boolean;
  handleOpen: () => void;
};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  background: "#fff", //theme.palette.background.default,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: `${DRAWER_WIDTH}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const AppTopBar: React.FC<AppTopBarProps> = ({ open, handleOpen }) => {
  const theme = useTheme();
  const handleLogout = () => {
    localStorage.clear();
    location.reload();
  }

  return (
    <AppBar position="fixed" open={open} elevation={0} variant={"outlined"}>
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          onClick={handleOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          marginLeft={"auto"}
          color={theme.palette.primary.main}
          alignSelf={"flex-end"}
        >
          <Button onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
