import React, { useEffect, useState } from "react";
import { Card, Typography, Box } from "@mui/material";
import {
  buildMetricBlocks,
  getAverageForData,
  getCorrectValueFormat,
  mapTimelinerecordsForChart,
} from "../utils/common.utils";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { SleepStreamChart } from "./SleepStreamChart";
import { RangeDatePicker } from "./RangeDatePicker";
import { IRangeDate } from "../models/IRangeDate";
import { ISleepData, ISleepStrem } from "../models/ISleepData";
import { timelineService, vitalService } from "../services";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  setCurrentRecordCategory,
  setPickerData,
  setTimelineRecords,
  setTimelineRecordsLoaded,
} from "../store/info.slice";
import { ChartsHolder, MetricsHolder } from "./CommonChart";
import { SummaryChart } from "./SummaryChart";
import { ITimelineRecord } from "../models/ITimelineRecord";

dayjs.extend(duration);
dayjs.extend(utc);

export const getSleepDataBlocks = (objectData: any) => {
  return buildMetricBlocks(objectData, [
    "userId",
    "records",
    "bedtime_start",
    "bedtime_stop",
    "timezone_offset",
    "id",
    "date",
    "source",
    "user_id",
    "user_key",
    "sleep_stream",
  ]);
};

export type SleepTabDataProps = {
  sleepData: ISleepData[];
};

const initialSleepStreamData = {
  heartrate: [],
  hrv: [],
  respiratory_rate: [],
  hypnogram: [],
};

export const SleepDataTab: React.FC<SleepTabDataProps> = ({ sleepData }) => {
  const client = useSelector((state: RootState) => state.client.client);
  const [dataProviders, setDataProviders] = useState<string[]>([]);
  const [blocks, setBlocks] = useState<any[]>([]);
  const [sleepStream, setSleepSteam] = useState<ISleepStrem>(
    initialSleepStreamData
  );
  const [sleepStreamLoaded, setSleepSteamLoaded] = useState(false);
  const [sleepSummary, setSleepSummary] = useState(sleepData);
  const { sleepRangePicker, timelineRecords } = useSelector(
    (state: RootState) => state.info
  );
  const dispatch = useDispatch();

  const handleSleepRangePickerChanged = (dates: IRangeDate) => {
    dispatch(setPickerData({ pickerType: "sleep", dates }));
  };

  const handPointClicked = (props: any, ev: any) => {
    setSleepSteamLoaded(false);
    if (props.activePayload.length) {
      vitalService
        .getSleepStream(props.activePayload[0]?.payload?.id)
        .then((resp) => setSleepSteam(resp))
        .finally(() => setSleepSteamLoaded(true));
    }
  };

  const loadSleepData = async (newDates: IRangeDate) => {
    if (!client?.vitalUserId) return;

    const sleepData = await vitalService.getSleepSummary(
      client.vitalUserId,
      newDates
    );
    const recordsData = await timelineService
      .getUserTimelineData(client.id, newDates)
      .then((resp) => {
        dispatch(setCurrentRecordCategory(null));
        dispatch(setTimelineRecords(resp));
        return resp;
      })
      .finally(() => dispatch(setTimelineRecordsLoaded(true)));

    if (!sleepData || !sleepData?.sleep?.length) {
      return;
    }

    const providersInData = [
      ...new Set(
        sleepData && sleepData.sleep
          ? sleepData.sleep.map((el: any) => el && el.source.slug)
          : []
      ),
    ];
    setDataProviders(providersInData);

    const mergedData = _getMergedData(sleepData.sleep, recordsData);
    _loadBlocks(mergedData);
  };

  useEffect(() => {
    loadSleepData(sleepRangePicker);
  }, [sleepRangePicker]);

  useEffect(() => {
    if (sleepData && sleepData.length) {
      const mergedData = _getMergedData(sleepData, timelineRecords);
      _loadBlocks(mergedData);
    }
  }, [sleepData]);

  const _loadBlocks = (blocksData: any) => {
    const avgData = getAverageForData(blocksData);
    setBlocks(getSleepDataBlocks(avgData));
    setSleepSummary(blocksData as any);
  };

  const _getMergedData = (
    sleepData: ISleepData[],
    recordsData: ITimelineRecord[]
  ) => {
    const mappedRecords = mapTimelinerecordsForChart(recordsData);
    return sleepData.map((el) => {
      el.records = [];
      for (const r of mappedRecords) {
        if (
          r &&
          dayjs
            .utc(el.date)
            .utcOffset(el.timezone_offset / 60)
            .isSame(r.date, "day")
        ) {
          el.records.push(r);
          el.record = r.record;
        }
      }
      return el;
    });
  };

  return (
    <>
      <RangeDatePicker
        dates={sleepRangePicker}
        onSelected={handleSleepRangePickerChanged}
        title={"Sleeping hours"}
      />
      {sleepSummary && (
        <>
          <MetricsHolder>
            {blocks &&
              blocks.map((el: any, i: number) => {
                if (
                  el.label !== "bedtime start" &&
                  el.label !== "bedtime stop"
                ) {
                  return (
                    <Card
                      sx={{
                        padding: 1,
                        flex: "1 1 calc(20% - 16px)",
                      }}
                      key={i}
                    >
                      <Typography
                        variant={"subtitle2"}
                        textOverflow={"ellipsis"}
                        noWrap
                        sx={{
                          textTransform: "capitalize",
                          fontWeight: 600,
                        }}
                      >
                        {el.label
                          .toLowerCase()
                          .replace("average", "avg.")
                          .replace("respiratory", "resp.")
                          .replace("temperature", "temp.")}
                      </Typography>
                      <Typography fontSize={14}>
                        {getCorrectValueFormat(el.value, el.label)}
                      </Typography>
                    </Card>
                  );
                }
              })}
          </MetricsHolder>

          <ChartsHolder>
            <Card className={"innerCard"}>
              <Typography
                variant={"subtitle1"}
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                }}
              >
                Summary chart
              </Typography>
              <Typography variant={"subtitle2"}>
                {dataProviders.length > 0 && (
                  <>
                    Based on providers:{" "}
                    {dataProviders.map((el: any, i) => (
                      <Box
                        component={"span"}
                        key={i}
                        sx={{
                          border: 1,
                          padding: "0 5px",
                          borderRadius: 2,
                          marginRight: 1,
                          fontSize: 12,
                        }}
                      >
                        {el}
                      </Box>
                    ))}
                  </>
                )}
              </Typography>
              <SummaryChart
                data={sleepSummary}
                metrics={blocks}
                showMeals
                useDomains
                dateKey={"date"}
                chartType={"sleep"}
                onPointClicked={handPointClicked}
              />
            </Card>

            <Card className={"innerCard"}>
              <Typography
                variant={"subtitle1"}
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                }}
              >
                Stream chart
              </Typography>
              <SleepStreamChart data={sleepStream} loaded={sleepStreamLoaded} />
            </Card>
          </ChartsHolder>
        </>
      )}
    </>
  );
};
