import React from 'react'


export const IgluPage = () => {

  return (
    <>
      <iframe
        src={ `https://irinagain.shinyapps.io/shiny_iglu/` }
        width="100%" height="720px"
        frameBorder="0"></iframe>
    </>
  )

}
