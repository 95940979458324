import React from "react";
import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { BrowserView, MobileView } from "react-device-detect";
import { TextField } from "@mui/material";

export const BirthdayPicker = ({ formik }: any) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <BrowserView>
        <DesktopDatePicker
          closeOnSelect
          label="Birthday"
          value={
            formik.values.birthday || dayjs().subtract(18, "year").toISOString()
          }
          minDate={dayjs().subtract(70, "year").toDate()}
          maxDate={dayjs().subtract(18, "year").toDate()}
          onChange={(newValue) => {
            if (dayjs(newValue).isValid()) {
              formik.setFieldValue("birthday", dayjs(newValue).toISOString());
            }
          }}
          renderInput={(params) => <TextField {...params} helperText={null} />}
        />
      </BrowserView>
      <MobileView>
        <MobileDatePicker
          closeOnSelect
          label="Birthday"
          value={
            formik.values.birthday || dayjs().subtract(18, "year").toISOString()
          }
          minDate={dayjs().subtract(70, "year").toDate()}
          maxDate={dayjs().subtract(18, "year").toDate()}
          onChange={(newValue) => {
            if (dayjs(newValue).isValid()) {
              formik.setFieldValue("birthday", dayjs(newValue).toISOString());
            }
          }}
          renderInput={(params) => <TextField {...params} helperText={null} />}
        />
      </MobileView>
    </LocalizationProvider>
  );
};
