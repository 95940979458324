import React from "react";
import styled from "@emotion/styled";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { getCorrectValueFormat } from "../utils/common.utils";
import { ETimelineRecordTypes } from "../models/ETimelineRecordTypes";
import { RecordTypeChip } from "./TimelineList";
import { Typography } from "@mui/material";
dayjs.extend(duration);
dayjs.extend(utc);

const TooltipHolder = styled.div((props) => ({
  border: "1px solid blue",
  borderRadius: 6,
  background: "#fff",
  padding: 10,
  boxShadow: "0 4px 8px 4px rgba(0,0,0,.2)",
  ["p"]: {
    fontSize: 12,
    margin: "2px 0",
    ["span"]: {
      fontWeight: 500,
      ["b"]: {
        textTransform: "capitalize",
      },
    },
  },
}));

const _displayDate = (date: string, timezone_offset: number) => {
  if (!timezone_offset) {
    return dayjs.utc(date).format("DD.MM.YYYY");
  }

  return dayjs
    .utc(date)
    .utcOffset(timezone_offset / 60)
    .format("DD.MM.YYYY");
};

const _displayTime = (dateTime: string, timezone_offset: number) => {
  if (!timezone_offset) {
    return dayjs.utc(dateTime).format("HH:mm");
  }
  return dayjs
    .utc(dateTime)
    .utcOffset(timezone_offset / 60)
    .format("HH:mm");
};

export const CustomChartTooltip = (element: any) => {
  const { active, payload, label, chartType } = element;

  if (!active || !payload || payload.length === 0) return null;
  const innerPaylod = payload[0].payload;

  return (
    <TooltipHolder>
      {(chartType === "sleep" || chartType === "activity") && (
        <h4 style={{ margin: 0 }}>
          <b>Date:</b>{" "}
          {_displayDate(innerPaylod.date, innerPaylod.timezone_offset)}
        </h4>
      )}

      {chartType === "workout" && (
        <h4 style={{ margin: 0 }}>
          <b>Date:</b>{" "}
          {_displayDate(innerPaylod.time_start, innerPaylod.timezone_offset)}
        </h4>
      )}

      {chartType === "cgm" && (
        <h4 style={{margin: 0}}>
          <b>Date time:</b>{" "}
          {_displayTime(innerPaylod.timestamp, 0)}{" "}
          {_displayDate(innerPaylod.timestamp, 0)}
        </h4>)
      }

      {!innerPaylod?.type && chartType === "sleep" && (
        <p>
          <span>
            <b>Sleep time:</b>{" "}
          </span>
          {_displayTime(innerPaylod.bedtime_start, innerPaylod.timezone_offset)}{" "}
          -{" "}
          {_displayTime(innerPaylod.bedtime_stop, innerPaylod.timezone_offset)}
        </p>
      )}
      {chartType === "workout" && (
        <p>
          <span>
            <b>Work time:</b>
          </span>
          {_displayTime(innerPaylod.time_start, innerPaylod.timezone_offset)} -{" "}
          {_displayTime(innerPaylod.bedtime_stop, innerPaylod.timezone_offset)}
        </p>
      )}
      
      {payload.map((el: any) => {
        if (el?.dataKey !== "record") {
          return (
            <p key={el?.dataKey}>
              <span>
                <b>{el?.dataKey}:</b>
              </span>{" "}
              {getCorrectValueFormat(+el.value, el.dataKey)} {el?.payload?.unit ? el?.payload?.unit : ''}
            </p>
          );
        } else {
          return el.payload.records.map((re: any) => (
            <div
              key={Math.random()}
              style={{
                border: "1px solid",
                marginBottom: "2px",
                borderRadius: 4,
                padding: 2,
              }}
            >
              <p>
                <b>{"Type: "}</b>
                <RecordTypeChip recType={re.type} />
              </p>
              <p>
                <b>{"Title: "}</b>
                {re.recordData.title || ""}
              </p>
              <p>
                <b>{"Message: "}</b>
                {re.recordData.message || ""}
              </p>
              {re.type === ETimelineRecordTypes.Meal && (
                <div key={Math.random()} className="custom-tooltip">
                  <p>
                    <b>{"Proteins: "}</b>
                    {re.recordData.proteins || 0}g
                  </p>
                  <p>
                    <b>{"Carbs: "}</b>
                    {re.recordData.carbs || 0}g
                  </p>
                  <p>
                    <b>{"Fats: "}</b>
                    {re.recordData.fats || 0}g
                  </p>
                </div>
              )}
            </div>
          ));
        }
      })}
    </TooltipHolder>
  );
};

export const GlucoseChartTooltip = (props: any) => {
  const { active, payload, label } = props;

  if (!active || !payload || payload.length === 0) return null;
  const cgmPayload = payload[0]?.payload;
  const recordPayload = payload[1]?.payload;

  return (
    <TooltipHolder>
      <Typography variant={"caption"}>
        <b>Date time:</b> {dayjs.utc(label).format("MMM DD HH:mm A")}
      </Typography>
      {cgmPayload && (
        <Typography variant={"body1"}>
          <b>Value:</b>
          {cgmPayload.value} {cgmPayload.unit}
        </Typography>
      )}

      {recordPayload && (
          <div style={{
            border: "1px solid",
            marginBottom: "2px",
            borderRadius: 4,
            padding: 2,
          }}>
            <p>
              <b>{"Date: "}</b>
              {dayjs(recordPayload.date).format("MMM DD")}
            </p>
            <p>
              <b>{"Type: "}</b>
              <RecordTypeChip recType={recordPayload.type} />
            </p>
            <p>
              <b>{"Title: "}</b>
              {recordPayload.recordData.title || ""}
            </p>
            <p>
              <b>{"Message: "}</b>
              {recordPayload.recordData.message || ""}
            </p>
            <p>
              <b>{"Proteins: "}</b>
              {recordPayload.recordData.proteins || 0}g
            </p>
            <p>
              <b>{"Carbs: "}</b>
              {recordPayload.recordData.carbs || 0}g
            </p>
            <p>
              <b>{"Fats: "}</b>
              {recordPayload.recordData.fats || 0}g
            </p>
          </div>
        )}
    </TooltipHolder>
  );
};
