import React from "react";
import { styled } from "@mui/material/styles";
import {
  Toolbar,
  OutlinedInput,
  InputAdornment,
  Typography,
} from "@mui/material";
import { SearchOutlined, SearchRounded } from "@mui/icons-material";
import { Stack } from "@mui/system";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 180,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 280 },
  "& fieldset": {
    borderWidth: `1px !important`,
    // borderColor: `${theme.palette.grey[500_32]} !important`,
  },
  "& input": {
    padding: '8.5px 14px 8.5px 0',
  }
}));

// ----------------------------------------------------------------------

export type UserListToolbarProps = {
  filterName: string;
  onFilterName: (e: any) => void;
};

export const UserListToolbar: React.FC<UserListToolbarProps> = ({
  filterName,
  onFilterName,
}) => {
  return (
    <RootStyle style={{justifyContent: 'flex-start'}}>
      {/* <SearchRounded /> */}
      {/* <TextField type={'search'} variant={'outlined'}/> */}
      <SearchStyle
        value={filterName}
        onChange={onFilterName}
        placeholder="Search user..."
        startAdornment={
          <InputAdornment position="start">
            <SearchRounded />
          </InputAdornment>
        }
      />

      <Stack sx={{
        marginLeft: 2,
      }}>
        <Typography variant={'h6'}>Filters</Typography>

      </Stack>
    </RootStyle>
  );
};
