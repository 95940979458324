import * as yup from "yup";

export const userValidationSchema = yup.object({
  phoneNumber: yup
    .string()
    .matches(/^[0-9]*$/, "Can contain only numbers")
    .min(10, "Must be at least 10 digits")
    .required("Phone number is required"),
  password: yup.string().min(6, "Mub be at least 6 characters length").notRequired().nullable(),
  firstName: yup.string().min(3, "Must be at least 3 characters").required(),
  lastName: yup.string().min(3, "Must be at least 3 characters").required(),
  userNote: yup.string().notRequired().nullable(),
  weight: yup
    .number()
    // .test(
    //   "Is positive?",
    //   "ERROR: The number must be greater than 0!",
    //   (value) => (value ? value > 0 : false)
    // )
    .nullable()
    .notRequired(),
  height: yup
    .number()
    // .test(
    //   "Is positive?",
    //   "ERROR: The number must be greater than 0!",
    //   (value) => (value ? value > 0 : false)
    // )
    .nullable()
    .notRequired(),
});
